import "bootstrap/dist/css/bootstrap.min.css";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import WorkFlow from "./WorkFlow";
import {WorkFlowNew} from "./WorkFlow/workflow";
import "./styles/workSpace.css";

import {PageBuilder} from "./PageBuilder/pagebuilder";
import WorkFlowBuilder from "./WorkFlowBuilder/FlowBuilder";
import "./styles/workFlowRightPanel.css";
import "./styles/workflow.css";

import BrandSetting from "./Components/settings/BrandSetting";
import PortalSetting from "./Components/settings/PortalSetting";

import "react-loading-skeleton/dist/skeleton.css";
import {QueryClient, QueryClientProvider} from "react-query";
import WorkflowPreview from './Components/mockup/workflowPreview';
import SettingLayout from './SettingLayout';
import Flow from "./WorkFlowBuilder/Flow";
import SidePanel from "./WorkFlowBuilder/SidePanel";
import * as Sentry from "@sentry/react";
import macMockup from "./Components/mockup/macMockup";
import { CustomerRoot, CustomerSDK } from "./CustomerSDK";
import ApiTest from "./Components/mockup/apiTest/apitest";

import {ProtectedRoute} from "@idflow/styles";

const path = (p) => "/home" + p;

const router = createBrowserRouter([
    {
        path: path("/workflows"),
        element: <ProtectedRoute> <WorkFlowNew/> </ProtectedRoute>,
        children: [
            {
                index: true,
                element: <WorkFlow/>,
            },
            {
                path: ":workflowId",
                element: <WorkFlowBuilder/>,
                children: [
                    {
                        index: true,
                        element: (
                            <div className="main_rajer">
                                <SidePanel/>
                                <Flow/>
                            </div>
                        ),
                    },
                    {
                        element: <WorkflowPreview/>,
                        path: "preview",
                    },
                    {
                        path: "pages/",
                        element: <PageBuilder/>,
                    },
                    {
                        path: "pages/:pageId",
                        element: <PageBuilder/>,
                    },
                    {
                        path: "settings",
                        element: <SettingLayout/>,
                        children: [
                            {
                                index: true,
                                element: <BrandSetting/>,
                            },
                            {
                                path: "brand",
                                element: <BrandSetting/>,
                            },
                            {
                                path: "portal",
                                element: <PortalSetting/>,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: path("/workflows/preview/:workflowId"),
        element: <CustomerSDK/>
    }
]);

const queryClient = new QueryClient();
const DSN = "https://48ed957d34d342cbbbfe55ed9c4da998@o4505214857838592.ingest.sentry.io/4505584043884544";

Sentry.init({
    dsn: DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
})

const App = () => {
    return (

        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router}></RouterProvider>
        </QueryClientProvider>

    );
};

export default App;
