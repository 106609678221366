import React, { useMemo, useState, useEffect } from "react"
import { Handle, Position, useStoreApi, useUpdateNodeInternals } from "reactflow";
import { NodeHeader, getMeasurements } from "../../Common";
import { TbMathSymbols } from "react-icons/tb";
import Modal from 'react-bootstrap/Modal';
import { Form } from "react-router-dom";
import Icons from "../../../../../assets/imageUrl";
import SidePanelClose from "../../../../../Components/sidePanelClose/SidePanelClose"
import { useNodeData } from "../../../../store/selector";
export const MatrixPanel = ({ id, type = "Matrix" }) => {
    const [data, setData] = useNodeData(id);
   


    return (
        <>
            <div className="right_panel_workflow">
                <SidePanelClose nodeName="Matrix" />
                <section className="sideInnerParent">
                    <div>
                    <label className="nodePanel-labels mt-2">{type + " Name"}</label>
<div className="labelInputFull">
<input
                        type="text"
                        className="nodePanel-inputs mb-2"
                        value={data.name || ""}
                        maxLength={15}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                        placeholder={type + " Name"}
                    />
</div>
                    </div>
                    <div>
                    <label className="nodePanel-labels mt-2">Row name</label>
<div className="labelInputFull">
<input
                        type="text"
                        className="nodePanel-inputs mb-2"
                        value={data.name || ""}
                        maxLength={15}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                        placeholder="Enter row name"
                    />
</div>
<label className="nodePanel-labels mt-2">No of rows</label>
<div className="labelInputFull">
<input
                        type="number"
                        className="nodePanel-inputs mb-2"
                        value={data.name || ""}
                        maxLength={15}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                        placeholder="Select"
                    />
</div>
                    </div>
                    <div>
                    <label className="nodePanel-labels mt-2">column name</label>
<div className="labelInputFull">
<input
                        type="text"
                        className="nodePanel-inputs mb-2"
                        value={data.name || ""}
                        maxLength={15}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                        placeholder="Enter column name"
                    />
</div>
<label className="nodePanel-labels mt-2">No of columns</label>
<div className="labelInputFull">
<input
                        type="number"
                        className="nodePanel-inputs mb-2"
                        value={data.name || ""}
                        maxLength={15}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                        placeholder="Select"
                    />
</div>
                    </div>
                    <div>
                        <button className="createMatrixButton">
<span>+</span>Create
                        </button>
                    </div>
                </section>
                
                
            </div>

        </>
    )
}

export const MatrixModal =({ showMatrixModal, closeMatrixModal })=>{
return (<Modal show={showMatrixModal} onHide={closeMatrixModal}>
    <Modal.Header closeButton>
      <Modal.Title>Modal Title</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Modal content goes here.</p>
    </Modal.Body>
    <Modal.Footer>
      {/* <Button variant="secondary" onClick={closeModal}>
        Close
      </Button> */}
      {/* <Button variant="primary" onClick={closeModal}>
        Save Changes
      </Button> */}
    </Modal.Footer>
  </Modal>)
}
const Matrix = ({ id, data, selected }) => {
    const store = useStoreApi();
    const updateNodeInternals = useUpdateNodeInternals();

    updateNodeInternals(id);

    const [, Right] = getMeasurements(id, store);
    const [accessSelected, setAccessSelected] = useState(selected);

    useEffect(() => {
        setAccessSelected(selected)
    }, [selected])
    return (
        <>
            <div
                style={{ backgroundColor: "#ffecec", border: selected ? `solid 2px${"#FF9900"}` : "" }}
                className={
                    `${"set_msgcard"} ${"commonHeader"}`
                    //    +
                    //     (data.isStartNode ? "start_node" : "") +
                    //     (!data.isStartNode && selected ? "selected_node" : "")
                }>
                <NodeHeader
                    name={"Matrix"}
                    icon={Icons.conditionNodeLogo}
                    onDelete={data.delete}
                    isStartNode={data.isStartNode}
                    accessSelected={accessSelected}
                />
                <div className="matrixNodeStyle">
                    <div>
                        <p>
                            POST
                        </p>
                        <Handle type="source" position={Position.Right} className="commonHandleStyle"></Handle>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Matrix