import {
  Handle,
  Position,
  useStoreApi,
  useUpdateNodeInternals,
} from "reactflow";
import { getMeasurements, NodeHeader, StartNode } from "../Common";
import { TbMathSymbols } from "react-icons/tb";
import { React,useState,useEffect } from "react";
import SidePanelClose from "../../../../Components/sidePanelClose/SidePanelClose";
import { useNodeData } from "../../../store/selector";
import Icons from "../../../../assets/imageUrl";
import { AWSColor } from "../../../../assets/imageUrl";
export const AwsS3Panel = ({id, type = "AwsS3", vars = [] }) => {
    const [data, setData] = useNodeData(id);
  return (
    <>
      <div className={"right_panel_workflow"}>
        <SidePanelClose nodeName="AwsS3" />
        <section className="sideInnerParent">
          <div >
            <label className="nodePanel-labels">{type + " Name"}</label>
            <div className="labelInputFull">
              <input
                type="text"
                className="form-control mb-2"
                value={data.name || ""}
                maxLength={15}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                placeholder={type + " Name"}
              />
            </div>
          </div>

          <div>
            <label className="nodePanel-labels">Region</label>
            <div className="labelInputFull">
              <input
                type="text"
                className="form-control mb-2"
                value={data.region || ""}

                onChange={(e) =>
                  setData({ ...data, region: e.target.value })
                }
              />
            </div>
          </div>

          <div>
            <label className="nodePanel-labels">Bucket</label>
            <div className="labelInputFull">
              <input
                type="text"
                className="form-control mb-2"
                value={data.bucket || ""}

                onChange={(e) =>
                  setData({ ...data, bucket: e.target.value })
                }
              />
            </div>
          </div>
          <div>
            <label className="nodePanel-labels">AWS Access Key</label>
            <div className="labelInputFull">
              <input
                type="text"
                className="form-control mb-2"
                value={data.accessKey || ""}

                onChange={(e) =>
                  setData({ ...data, accessKey: e.target.value })
                }
              />
            </div>
          </div>
          <div>
            <label className="nodePanel-labels">AWS Secret Key</label>
            <div className="labelInputFull">
              <input
                type="text"
                className="form-control mb-2"
                value={data.secretKey || ""}

                onChange={(e) =>
                  setData({ ...data, secretKey: e.target.value })
                }
              />
            </div>
          </div>
          <div>
            <label className="nodePanel-labels">Operation</label>
            <div className="labelInputFull">
              <select
                className="right_section_select mt-2"
                onChange={(e) =>
                  setData({
                    ...data,
                    operation: { ...data.operation, type: e.target.value },
                  })
                }
                value={data?.operation?.type}
              >
                <option selected disabled>
                  Select Operation
                </option>
                <option value="GetObject">Download Object</option>
                <option value="PutObject">Upload Object</option>
              </select>
            </div>
          </div>
          {data?.operation?.type == "PutObject" && (
            <>
              <div>
                <label className="nodePanel-labels">Object Key</label>
                <div className="labelInputFull">
                  <input
                    type="text"
                    className="form-control mb-2"
                    value={data?.operation?.objectKey || ""}
                    onChange={(e) =>
                      setData({
                        ...data,
                        operation: {
                          ...data.operation,
                          objectKey: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div>
                <label className="nodePanel-labels">Input</label>
                <div className="labelInputFull">
                  <input
                    type="text"
                    className="form-control mb-2"
                    value={data?.operation?.input || ""}
                    onChange={(e) =>
                      setData({
                        ...data,
                        operation: { ...data.operation, input: e.target.value },
                      })
                    }
                  />
                </div>
              </div>
            </>
          )}
          {data?.operation?.type == "GetObject" && (
            <>
              <div>
                <label className="nodePanel-labels">Object Key</label>
                <div className="labelInputFull">
                  <input
                    type="text"
                    className="form-control mb-2"
                    value={data?.operation?.objectKey || ""}
                    onChange={(e) =>
                      setData({
                        ...data,
                        operation: {
                          ...data.operation,
                          objectKey: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>

              <div>
                <label className="nodePanel-labels">Output</label>
                <div className="labelInputFull">
                  <input
                    type="text"
                    className="form-control mb-2"
                    value={data?.operation?.output || ""}
                    onChange={(e) =>
                      setData({
                        ...data,
                        operation: {
                          ...data.operation,
                          output: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </>
          )}
        </section>
      </div>
    </>
  );
};

const AwsS3 = ({ id, data, selected }) => {
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected)
  }, [selected])
  return (
    <>
      <div
        style={{
          backgroundColor: "#ffecec",
          border: selected ? `solid 2px${"#FF9900"}` : "",
        }}
        className={"set_msgcard " + "commonHeader"}
      >
        <NodeHeader
          name={"Aws S3"}
          icon={AWSColor}
          onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        />

        <div className="conditionNodeStyle">
          <div>
            <p>AwsS3 Node</p>
            <Handle
              id="yes"
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AwsS3;
