import React from "react";
import Icons from "../assets/imageUrl";
import useFlow from "./store/flowStore";
import { useCustomNodes } from "./store/selector";
import { 
  sidePanelAPICall,
  sidePanelChat,
  sidePanelCondition,
  sidePanelConversion,
  sidePanelEmail,
sidePanelMatrix,
sidePanelNotification,
sidePanelPage,
sidePanelSMS,
sidePanelUpdate,
sidePanelZapier,
sidePanelList,
sidePanelMessage,
sidePanelButton,
sidePanelQuestion,
sidePanelWebhook,
sidePanelAWSBucket,
   sidePanelTimeDelay,
   sidePanelTimeRandom
 } from "../assets/imageUrl";

export const commonNodes = [
  {
    name: "Condition",
    imageLink:sidePanelCondition,
    type: "Condition"
  },
  {
    name: "API Call",
    imageLink:sidePanelAPICall,
    type: "APICall"
  },
  {
    name: "Update",
    imageLink:sidePanelUpdate,
    type: "UpdateVariable"
  },
  {
    name: "Zapier",
    imageLink:sidePanelZapier,
    type: "Trigger"
  },
  {
    name: "Conversion",
    imageLink:sidePanelConversion,
    type: "Conversion"
  },
  {
    name: "Matrix",
    imageLink:sidePanelMatrix,
    type: "Matrix"
  },
  {
    name: "AWS S3",
    imageLink:sidePanelAWSBucket,
    type: "AwsS3",
  },
  {
    name: "Random",
    imageLink:sidePanelTimeRandom,
    type: "Random",
  },
  {
    name: "Time Delay",
    imageLink:sidePanelTimeDelay,
    type: "TimeDelay",
  },
  {
    name: "Switch Node",
    imageLink:sidePanelTimeDelay,
    type: "SwitchNode",
  }
];

export const communicationNodes = [
  {
    name: "Chat",
    imageLink:sidePanelChat,
    type: "Novu",
    subType: "Chat"
  },
  {
    name: "Email",
    imageLink:sidePanelEmail,
    type: "Novu",
    subType: "Email"
  },
  {
    name: "Notification",
    imageLink:sidePanelNotification,
    type: "Novu",
    subType: "Notification"
  },
  {
    name: "SMS",
    imageLink:sidePanelSMS,
    type: "Novu",
    subType: "SMS"
  }
];

const webNodes = [
  {
    name: "Page",
    imageLink:sidePanelPage,
    type: "Page"
  }
];

const apiNodes = [
  {
    name: "Webhook",
    imageLink:sidePanelWebhook,
    type: "Webhook"
  }
];

const whatsappNodes = [
  {
    name: "Message",
    // imageLink: "bx bx-message-rounded",
    imageLink:sidePanelMessage,
    type: "Message"
  },
  {
    name: "Question",
    // imageLink: "bx bx-question-mark",
    imageLink:sidePanelQuestion,
    type: "Question"
  },
  {
    name: "Buttons",
    // imageLink: "bx bx-joystick-button",
    imageLink:sidePanelButton,
    type: "InteractiveButtons"
  },
  {
    name: "List",
    // imageLink: "bx bx-list-ul",
    imageLink:sidePanelList,
    type: "InteractiveList"
  }
];

export const Node = ({ node }) => {
  return <div className="col-lg-6 col-sm-6 p-0"
              draggable={true}
              onDragStart={(event) => {
                event.dataTransfer.setData("type", node.type);
                if (node.subType) {
                  event.dataTransfer.setData("subType", node.subType);
                }
                if (node.id) {
                  event.dataTransfer.setData("nodeId", node.id);
                }
                event.dataTransfer.effectAllowed = "move";
              }}>
    <div className="node-start node-border">
      <img src={node?.imageLink ||  Icons?.backendApi } className="node-img"  alt="Node"/>
      <p className="node-name mb-2">{node?.name}</p>
    </div>
  </div>;
};


export const defaultNodeItems = type => type === "api" ?
  apiNodes : type === "web" ? webNodes : whatsappNodes;


