import {useCallback, useEffect, useRef, useState} from "react";
import { PageContext } from "components";
import { Components } from "components";
import "./loadingPB.css";
import { useParams } from "react-router-dom";
import { useFullScreen } from "../WorkFlowBuilder/hooks";

const WS_URL = "ws://127.0.0.1:8080/ws?workflowId=0yvWpbiztOjWxg0M-DWyvgx9tVA";

const JOB_ID = "jobId";

const errorScreen = {"id":"9sTeV1Urjz","name":"Error 500","components":[{"id":"main","type":"MainContainer","props":{"bgcolor":"#ecf8ff","hPadding":0,"vPadding":0},"components":[{"id":"1kAMfEQRI7","name":"Container","type":"Container","components":[{"id":"ecMhJ3T0Gy","name":"Container","type":"Container","components":[{"id":"eOXKAy6pwe","name":"Text","type":"Text","props":{"alignment":"center","style":"subtitle2","text":"Error 500"}},{"id":"p55ENGngKe","name":"Image","type":"Image","props":{"image":"https://stg-api.idflow.com/v1/files/1_YPHo8tC6uGMcvXyFdcb","width":"70"}},{"id":"jLrm8d13Ku","name":"Text","type":"Text","props":{"alignment":"center","style":"subtitle2","text":"Internal server error","textColor":"rgba(232,117,96,1)"}},{"id":"5YWAZFGoTX","name":"Text","type":"Text","props":{"style":"subtitle2","alignment":"center","text":"The server encountered an error and could not complete your request.","hPadding":51}},{"id":"8RlBFLv6Xc","name":"Text","type":"Text","props":{"text":"{{code}}","style":"subtitle2","alignment":"center"}},{"id":"yftQ4EKEUi","name":"Text","type":"Text","props":{"text":""}},{"id":"AWWFHmmgsg","name":"Text","type":"Text","props":{"text":"{{message}}","style":"subtitle3","alignment":"center"}}],"props":{"width":"","direction":"column"}}],"props":{"direction":"column","bgcolor":"rgba(236,248,255,1)","vPadding":0,"centerdiv":true}}]}],"isStartNode":false}

const useWebSocket = workflowId => {

  let url = `wss://stg-web.idflow.com/ws?workflowId=${workflowId}`;
  // let url = `ws://localhost:8010/ws?workflowId=${workflowId}`;
  const [pageData, setPageData] = useState({});
  const [vars, setVars] = useState({});
  const [loading, setLoading] = useState(true);
  const [shouldReconnect, setShouldReconnect] = useState({});
  const [debug, setDebug] = useState(false);

  const socketConnection = useRef(null);

  const sendMessage = message => socketConnection.current.send(JSON.stringify(message));
  const addToVars = newVars => setVars(vars => ({...vars, ...newVars}))


  const onOpen = () => {
    console.log("Connection is Opened");
    sendMessage({ "type": "Auth", "workflowId": workflowId, "jobId": localStorage.getItem(JOB_ID) });
  };

  const onClose = () => {
    setTimeout(() => setShouldReconnect({}), 1000)
  };


  const events = {
    buttonClick: (buttonId, fromLoader = false) => {
      console.log("Button is clicked", buttonId);

      setLoading(fromLoader ? buttonId : true);

      sendMessage({ type: "ButtonClick", buttonId });
    },

    formSubmit: (formId, inputs) => {
      console.log("form is submitting", formId, inputs);
      setLoading(true);
      // Add variables to vars
      addToVars(inputs)
      sendMessage({ type: "FormSubmit", formId, inputs });
    }
  };

  const onMessage = ev => {
    let message = JSON.parse(ev.data);

    switch (message.type) {

      case "Auth":
        console.log("Authentication with server is ", message.message);
        break;

      case "ActivePage":
        setPageData(message.page);
        setLoading(false);
        break;

      case "SessionUpdate":
        if (message.jobId) {
          localStorage.setItem(JOB_ID, message.jobId);
        }
        break;
      case "VarsUpdate":
          addToVars(message?.vars)
        break;

      case "ServerError":
        localStorage.removeItem(JOB_ID)
        setPageData(errorScreen);
        setVars({code: message?.code, message: message?.message})
        setLoading(false);
        break;

      default:
        console.log("Unknown Message is received from the server | Message:- ", message);
    }


  };

  useEffect(() => {

    const connection = new WebSocket(url);
    // Register Callbacks

    connection.onopen = () => onOpen();

    connection.onclose = () => onClose();
    connection.onmessage = ev => onMessage(ev);

    socketConnection.current = connection;
    return () => connection.close();
  }, [url, shouldReconnect]);

  useEffect(() =>{
    let debug = localStorage.getItem("debug");
    if (debug === "true"){
      setDebug(true);
    }
  }, []);

  return {
    socketConnection,
    page: pageData,
    vars: vars,
    events,
    loading,
    debug

  };
};


export const CustomerRoot = ({ workflowId }) => {
  const {
    socketConnection,
    page, events,
    vars, loading, debug
  } = useWebSocket(workflowId);
  //
  // console.log(vars);

  return <div>
    <PageContext.Provider value={{ page, mode: "sdk", events, vars, loading, debug }}>
      {typeof loading === "boolean" && loading === true ? <LoadingComponent /> :
        <Components components={page?.components || []} />}
    </PageContext.Provider>
  </div>;
};

export const LoadingComponent = () => {
  return (
    <div className="loadingDivPB">

      <div className="loaderPB">
      </div>
    </div>
  );
};


export const CustomerSDK = () => {
  useFullScreen();


  const params = useParams();
  return <CustomerRoot {...params} />;
};