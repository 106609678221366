import React, { useState } from "react";
import { useActiveComponent, usePage } from "components";
import { getInputs } from "components";
import { generateId } from "../utils/store";
import PageBuilderAssets from "../assets/pageBuilder/pageBuilderAssets";
import { useGetPage, usePageId } from "components";
import useFlow from "../WorkFlowBuilder/store/flowStore";
import { useNavigate } from "react-router-dom";
import Resource from "../api/resource";
import { SketchPicker } from 'react-color';

const InputField = ({
    type = "text",
    value = "",
    setValue = () => { },
    placeHolder = "Enter",
    name = "Text",
    defaultValue,
}) => {
    return (
        <>
            <div className="PB-rightside-panel">
                <label htmlFor="name" className="PB-side-input-label">{name}</label>
                <input
                    type={type}
                    placeholder={placeHolder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)} />
            </div>
        </>
    );
};

const FileInput = ({ name, value, setValue, subType }) => {

    const fileChange = async (e) => {
        let formData = new FormData()

        const selectedFile = e.target.files[0];
        formData.append("file", selectedFile);

        try {
            const response = await Resource("files").create(formData);

            let fileId = response.id;

            setValue(`https://stg-api.idflow.com/v1/files/${fileId}`) // TODO Make it Dynamic

        } catch (error) {
            console.error("Error uploading file:", error);
        }
        console.log(selectedFile);
    };


    return (
        <div className="PB-rightside-panel">
            <label htmlFor="name" className="PB-side-input-label">{name}</label>
            <input type="file" accept={subType}
                onChange={fileChange}
            />
        </div>
    );
};

const ProgressInput = ({ name, value, setValue, defaultValue, min = 0, max = 100 }) => {
    const [progress, setProgress] = [value || defaultValue, setValue];

    const handleDragStart = (e) => {
        e.dataTransfer.setData("text/plain", e.target.id);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedProgress = parseInt(e.dataTransfer.getData("text/plain"), 10);
        setProgress(droppedProgress);
    };
    const handleDrag = (e) => {
        const updatedProgress = parseInt(e.target.value, 10);
        setProgress(updatedProgress);
    };

    return (

        <div className="PB-rightside-panel mb-3">
            <label htmlFor="name" className="PB-side-input-label">{name}</label>
            <div className="PB-range-slider-div">
                <input id="myRange" className="PB-range-slider mb-0 p-0" min={min}
                    max={max}
                    value={progress}
                    onChange={handleDrag}
                    onDragStart={handleDragStart}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop} type="range" />
                <p className="PB-range-slidervalue mb-0">{value ? value : "0"}px</p>
            </div>
        </div>

    );
};

const AlignmentInput = ({
    name = "Alignments",
    value = "left",
    setValue,
}) => {
    return (
        <div className="PB-rightside-panel mb-3">
            <label htmlFor="name" className="PB-side-input-label">{name}</label>
            <ul>
                <li onClick={() => setValue("left")}><img src={PageBuilderAssets?.leftAlign} className={` ${value === "left" ? "PBactivealign" : ""
                    }`} /></li>
                <li onClick={() => setValue("center")}><img src={PageBuilderAssets?.centerAlign} className={` ${value === "center" ? "PBactivealign" : ""
                    }`} /></li>
                <li onClick={() => setValue("right")}><img src={PageBuilderAssets?.rightAlign} className={` ${value === "right" ? "PBactivealign" : ""
                    }`} /></li>
            </ul>
        </div>
    );
};

const Select = ({
    name = "Select",
    defaultValue,
    value,
    setValue,
    options = [],
}) => {
    return (
        <>
            <div className="PB-rightside-panel mb-3">
                <label htmlFor="name" className="PB-side-input-label">{name}</label>
                <select
                    style={{ color: "#000" }}
                    onChange={(e) => setValue(e.target.value)}
                >
                    {options.map((option) => {
                        return (
                            <option
                                value={option.value}
                                selected={option.value === (value || defaultValue)}
                            >
                                {option.name}
                            </option>
                        );
                    })}
                </select>
            </div>
        </>
    );
};

const ColorInput = ({ value, setValue, name }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleShowColor = () => {
        setDisplayColorPicker(!displayColorPicker);
    }

    return (
        <>

            <div className="PB-rightside-panel mb-3">
                <label htmlFor="name" className="PB-side-input-label">{name}</label>
                <div className="PB-color mb-2" onClick={handleShowColor}>
                    <div style={{ background: `${value}` }}></div>
                    <p className="mb-0">{value || "rgba(0, 0, 0, 1.0)"}</p>
                </div>
                {displayColorPicker && (
                    <SketchPicker
                        onChange={(color) => {
                            console.log(color);
                            const { r, g, b, a } = color.rgb;
                            setValue(`rgba(${r},${g},${b},${a})`);
                        }}
                        color={value}
                    />
                )}
            </div>

        </>
    );
};

const TimerInput = ({ type = "number", value = "", setValue = () => { }, name, placeHolder }) => {
    return (
        <>
            <div className="PB-rightside-panel mb-3">
                <label htmlFor="name" className="PB-side-input-label">{name}</label>
                <input
                    type={type}
                    placeholder={placeHolder}
                    value={value}
                    maxLength={2}
                    onChange={(e) => setValue(e.target.value)} />
            </div>
        </>
    )
}

const Toggle = ({ value, setValue, name }) => {
    return (
        <>
            <div className="PB-rightside-panel toggle mb-2">
                <label class="switch">
                    <input type="checkbox"
                        checked={value}
                        onChange={(e) => setValue(e.target.checked)} />
                    <span class="slider"></span>
                </label>
                <label htmlFor="name" className="PB-side-input-label m-2 mr-4">{name}</label>
            </div>
        </>
    );
};

const Direction = ({ name = "Directions", value = "row", setValue }) => {
    return (
        <>
            <div className="PB-rightside-panel">
                <label htmlFor="name" className="PB-side-input-label">{name}</label>
                <ul>
                    <li onClick={() => setValue("row")}><img src={PageBuilderAssets?.rowIcon} className={`${value === "row" ? "PBactivealign" : ""
                        }`} /></li>
                    <li onClick={() => setValue("column")}><img src={PageBuilderAssets?.columnIcon} className={`${value === "column" ? "PBactivealign" : ""
                        }`} /></li>
                </ul>
            </div>
        </>
    );
};

const CheckBox = ({ name, setValue }) => {
    return (
        <>
            <fieldset className="PBSideField">
                <li>{name}</li>
                <input
                    type="checkbox"
                    checked
                    onChange={(e) => setValue(e.target.checked)}
                    opacity
                />
            </fieldset>
        </>
    );
};

const DropDown = ({
    type = "text",
    value = [],
    setValue = () => { },
    placeHolder = "Value",
    name = "Value",
    defaultValue,
}) => {

    const addOption = () =>
        setValue([...value, { id: generateId(5), name: "", value: "" }]);
    const removeOption = (id) => setValue(value.filter((v) => v.id != id));
    const updateOption = (id, key, keyValue) => {
        let newValue = value.map((v) => {
            if (v.id == id) {
                v[key] = keyValue;
            }
            return v;
        });
        setValue(newValue);
    };

    return (
        <>
            <div className="PB-rightside-panel">
                {value.map((value) => (
                    <div key={value?.id} className="input-container">
                        <div className="PB-rightside-panel">
                            <div className="d-flex justify-content-between align-content-center">
                                <label htmlFor="name" className="PB-side-input-label">{name}</label>
                                <img src={PageBuilderAssets?.deleteIconBlack} onClick={() => removeOption(value?.id)} style={{ cursor: "pointer" }} />
                            </div>
                            <input
                                type={type}
                                onChange={(e) => updateOption(value.id, "name", e.target.value)}
                                placeholder="Option"
                                value={value?.name}
                                defaultValue={defaultValue}
                                className="mb-2"
                            />
                            <input
                                type={type}
                                onChange={(e) =>
                                    updateOption(value.id, "value", e.target.value)
                                }
                                placeholder="Value"
                                value={value?.value}
                                defaultValue={defaultValue}
                            />
                        </div>
                    </div>
                ))}
                <div className="PB-addOption" onClick={addOption}>
                    <img src={PageBuilderAssets?.addIcon} />
                    <p className="PB-addOption">Add Option</p>
                </div>
            </div >
        </>
    );
};

const TextInput = (prop) => <InputField type="text" {...prop} />;
const NumberInput = (prop) => <InputField type="number" {...prop} />;
const DateInput = (prop) => <InputField type="date" {...prop} />;
const ImageInput = (prop) => <FileInput accept="image/*" {...prop} />;
const URLInput = (prop) => <InputField type="url" {...prop} />;
const CheckBoxs = (prop) => <CheckBox type="" {...prop} />;
const DropDowns = (prop) => <DropDown type="input" {...prop} />;

const inputsMap = {
    imageInput: ImageInput,
    textInput: TextInput,
    numberInput: NumberInput,
    urlInput: URLInput,
    select: Select,
    alignment: AlignmentInput,
    direction: Direction,
    colorInput: ColorInput,
    progressInput: ProgressInput,
    fileInput: FileInput,
    toggle: Toggle,
    CheckBox: CheckBoxs,
    DropDown: DropDowns,
    timerInput: TimerInput,
    DateInput: DateInput
};

const DeleteButton = ({ onClick }) => {
    return (
        <div className="PB-rightside-panel">
            <div className="PB-btn-del">

                <button
                    className="PBdelete"
                    onClick={onClick}
                >
                    <img src={PageBuilderAssets?.deleteIcon} /> &nbsp;  Delete
                </button>
                <></>
            </div>
        </div>

    );
};

export const DefaultRightPanel = () => {
    const { name, setName, page, setPage } = usePage();
    const pageId = usePageId();
    const createPageNode = useFlow(flow => flow.createNewPageNode);
    const setNodes = useFlow(flow => flow.setNodes);
    const navigate = useNavigate();
    const getPage = useGetPage();

    return (
        <>
            <InputField value={name} setValue={setName} name={"Page Name"} />
            <div className="PB-rightside-panel">
                <div className="PB-btn-main">
                    <></>
                    <button
                        className="PBdelete"
                        onClick={() => {
                            if (pageId) {
                                setNodes(nodes => nodes.map(node => {
                                    if (node.id === pageId) {
                                        node.data = getPage();
                                    }
                                    return node
                                }))
                                alert("Page Saved");
                            } else {
                                createPageNode(getPage());
                                navigate("..")
                            }

                            // const text = JSON.stringify(page);
                            // const filename = `${name}.json`;
                            // const element = document.createElement("a");
                            // const file = new Blob([text], { type: "text/plain" });
                            // element.href = URL.createObjectURL(file);
                            // element.download = filename;
                            // document.body.appendChild(element);
                            // element.click();
                            // document.body.removeChild(element);

                        }}
                    >
                        <img src={PageBuilderAssets?.saveIocn} /> &nbsp;  {pageId ? "Save" : "Create a new Node"}
                    </button>

                </div>
            </div>
            <div className="PB-import">
                <h6>Import</h6>
            </div>
            <InputField
                value={JSON.stringify(page)}
                setValue={(page) => setPage(JSON.parse(page))}
                name={"Page Name"}
            />
        </>
    );
};

export const RightPanel = () => {
    const { active, activeId } = useActiveComponent();
    const { updateComponent, deleteComponent } = usePage();

    // const inputs = useMemo(() => itemsMap[active?.type]?.inputs, [active])
    const inputs = activeId && active ? getInputs(active.type) : [];

    const updateProp = (name, value) => {
        active.props = { ...active.props, [name]: value };
        updateComponent(active);
    };

    return (
        <div className="PBbg-color PBscrollalign" id={activeId}>
            <div className="PB-right-title">
                <h6 className="mb-0">{`${active?.type}`} Settings</h6>
            </div>
            {/* <h5 className="PBrightheading">
                {active?.name} {"  "} #{activeId?.slice(0, 7)}
            </h5> */}
            <section className="PB-right-sidebar-main">
                {activeId ? (
                    <div id={activeId}>
                        {inputs?.map((input) => {
                            let Tag = inputsMap[input.type];
                            if (Tag) {
                                return (
                                    <Tag
                                        id={activeId}
                                        {...input}
                                        value={active?.props ? active?.props[input?.value] : undefined}
                                        setValue={(value) => updateProp(input?.value, value)}

                                    />
                                );
                            }
                        })}
                        {activeId !== "main" && (
                            <DeleteButton onClick={() => deleteComponent(active.id)} />
                        )}
                    </div>
                ) : (
                    <DefaultRightPanel />
                )}
            </section>
        </div>
    );
};
