import * as Separator from '@radix-ui/react-separator';
import * as Slider from '@radix-ui/react-slider';
import { useEffect, useRef, useState } from "react";
import Upload_icon from "../../../assets/Upload_icon.svg";
import dark_logo from "../../../assets/dark_logo.svg";
import dark_theme from "../../../assets/dark_theme.svg";
import light_logo from "../../../assets/light_logo.svg";
import light_theme from "../../../assets/light_theme.svg";
import CheckCircle from '../../../assets/solar_check-circle-bold.svg';
import ColorCode from './colorCode';
import "./index.css";
import TextEditor from './textEditor';
import { useBrandSettings } from "../../../WorkFlowBuilder/store/selector";
import Loader from './SkeletonLoader/Loader';
import CustomButton from '../../core/Button';

const colorsTextArray = [
  { title: "Primary color",  value: "primary" },
  { title: "Secondary color", value: "secondary" },
]

const colorsButtonArray = [
  ...colorsTextArray,
  { title: "Text primary color", value: "textPrimary"},
  { title: "Text secondary color", value: "textSecondary" },
];

const colorInputArray = [
  { title:"Background color", value: "background"},
  { title:"Label color", value: "label"},
  { title:"Value color", value: "value"},
  { title:"Placeholder color", value: "placeHolder"},
  { title:"Border Line color",  value: "border"},
  { title:"Focus Shadow color",  value: "focusShadow"},
  { title:"Focus color",  value: "focus"},

]

const ProgressInput = ({title, value, setValue}) =>{
  return (
    <div className='progressInput_wrapper'>
      <p className="slider_label">{title}</p> {/* // TODO: apply font family */}
      <div className="SliderMain">
        {/* <span className='slider-top'></span> */}
        <Slider.Root className="SliderRoot" value={[value]} min={0} max={100} step={1} onValueChange={value => setValue(value[0])}>
          <Slider.Track className="SliderTrack  bg-dark">
            <Slider.Range className="SliderRange" />
          </Slider.Track>
          <Slider.Thumb className="SliderThumb ms-1 bg-dark" aria-label="Volume" />
        </Slider.Root>
        <span className='slider-cus'>{value} px</span>
      </div>
    </div>
  )
}


const BrandSetting = () => {

  const [brand, setBrand] = useBrandSettings();

  const [activeTheme, setActiveTheme] = [brand.theme, theme =>  setBrand({...brand, theme: theme })]

  const [lightLogo, setLightLogo] = [brand.logo.white, white => setBrand({...brand, logo: {...brand.logo, white}})];

  const [darkLogo, setDarkLogo] = [brand.logo.black, black => setBrand({...brand, logo: {...brand.logo, black}})];

  const [loading,setLoading] = useState(false);

  const [sliderBradius, setSliderBradius] = useState(0);
  const borderRadiusSlider = useRef(0);

  const handleChangeLight = (event) => {
    if (event.target.files?.length > 0) {
      const file = event.target.files[0];
      if (file){
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setLightLogo(base64String)
        }
        reader.readAsDataURL(file)
      }
    }
  };

  const handleChangeDark = (event) => {
    if (event.target.files?.length > 0) {
      const file = event.target.files[0];
      if (file){
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setDarkLogo(base64String)
        }
        reader.readAsDataURL(file)
      }
    }
  };

  const handleSlider = (value) => {
    setSliderBradius(value);
  }

  useEffect(() => {
    borderRadiusSlider.current = parseInt(sliderBradius.toString())
   }, [sliderBradius])



  return (
    <div className="brand_setting">
    {loading === true ? <Loader/> :
    <div className='brand_setting_wrapper'>
      <section className="g-setting-heading mx-0">
        <div className='text_section'>
          <p className="g-title">Brand settings</p>
          <p className="g-desc">Apply your unique business branding to maintain a familiar and recognizable experience for your users.</p>
        </div>
      </section>
      <div className="theme_box">
        <div className='row theme_box_row'>
          <div className="col-lg-3 theme_context">
            <p className="heading">Theme</p>
            <p className="desp">Choose a theme and personalize your product's visual style</p>
          </div>
          <div className='select_theme_wrapper col-lg-8'>
            <div className='theme_wrapper'>
              <p className="theme_name">Light</p>
              <div className="light_theme" onClick={() => setActiveTheme("light")}>
                <div className='img_wrapper'>
                  <img src={light_theme} alt="light-thmee" />
                </div>
                <div className="bottom_light_theme">
                  <p className="active_lighttext m-0">Light theme {activeTheme === "light" && "(Active)"}</p>
                  {activeTheme === "light" && <img src={CheckCircle} alt="check circle" />}
                </div>
              </div>
            </div>
            <div className='theme_wrapper'>
              <p className="theme_name">Dark</p>
              <div className="dark_theme" onClick={() => setActiveTheme("dark")}>
                <div className='img_wrapper'>
                  <img src={dark_theme} alt="dark-thmee" />
                </div>
                <div className="bottom_dark_theme">
                  <p className="active_darktext m-0">Dark theme {activeTheme === "dark" && "(Active)"}</p>
                  {activeTheme === "dark" && <img src={CheckCircle} alt="check circle" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="logo_box">
        <div className='row logo_box_row'>
          <div className="col-lg-3 theme_context">
            <p className="heading">Logo</p>
            <p className="desp">Upload a transparent .png or .svg image for your logo.</p>
          </div>
          <div className='col-lg-8 logo_category'>
            <div className='upload_logo_wrapper'>
              <p className="category_name">Primary</p>
              <p className="desp">For use on light backgrounds</p>
              <div className='img_wrapper'>
                <img src={light_logo}/>
              </div>
              <div className="upload_div">
                <label className="upload_btn">
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleChangeLight}
                  />
                  <img src={Upload_icon} />
                  <span>Upload logo</span>
                </label>
              </div>
            </div>
            <div className='upload_logo_wrapper'>
              <p className="category_name">Secondary</p>
              <p className="desp">For use on dark backgrounds</p>
              <div className='img_wrapper'>
                <img src={dark_logo}/>
              </div>
              <div className="upload_div">
                <label className="upload_btn">
                  <input
                    type="file"
                    hidden
                    onChange={handleChangeDark}
                    accept="image/*"
                  />
                  <img src={Upload_icon} />
                  <span>Upload logo</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="color_box">
        <div className='row color_row'>
          <div className="col-lg-3 color_context">
            <p className="heading">Color palette</p>
            <p className="desp">
              This color palette will be available on the overall site.
            </p>
          </div>
          <div className="col-lg-8 color_pallet_wrapper">
            <div className="row">
              <div className='element_category'>
                <div className='element_wrapper'>
                  <p className='element_name'>Buttons</p>
                  <ColorCode
                    colorsArray={colorsButtonArray}
                    colors={brand.colors.button}
                    setColors={button => setBrand({...brand, colors:{...brand.colors, button}})}
                  />
                  <div className='slider_wrapper'>
                    <ProgressInput
                      title="Border Radius"
                      value={brand?.colors?.button?.borderRadius}
                      setValue={borderRadius => setBrand({...brand, colors: {...brand.colors, button: {...brand.colors.button, borderRadius}}}) }
                    />
                    <ProgressInput
                      title="Box Shadow"
                      value={brand?.colors?.button?.boxShadow}
                      setValue={boxShadow => setBrand({...brand, colors: {...brand.colors, button: {...brand.colors.button, boxShadow}}}) }
                    />
                  </div>
                </div>
              </div>
              <div className='element_category'>
                <div className='element_wrapper'>
                  <p className="element_name">Inputs</p> {/* // TODO: apply font family */}
                  <ColorCode
                    colorsArray={colorInputArray}
                    colors={brand.colors.input}
                    setColors={input => setBrand({...brand, colors:{...brand.colors, input}})}
                  />
                  <div className='slider_wrapper'>
                    <ProgressInput
                      title="Border Radius"
                      value={brand?.colors?.input?.borderRadius}
                      setValue={borderRadius => setBrand({...brand, colors: {...brand.colors, input: {...brand.colors.input, borderRadius}}}) }
                    />
                    <ProgressInput
                      title="Box Shadow"
                      value={brand?.colors?.input?.boxShadow}
                      setValue={boxShadow => setBrand({...brand, colors: {...brand.colors, input: {...brand.colors.input, boxShadow}}}) }
                    />
                    <ProgressInput
                      title="Focus Shadow"
                      value={brand?.colors?.input?.focusShadow}
                      setValue={focusShadow => setBrand({...brand, colors: {...brand.colors, input: {...brand.colors.input, focusShadow}}}) }
                    />
                  </div>
                </div>
              </div>
              <div className='element_category'>
                <div className='element_wrapper'>
                  <p className="element_name">Text</p> {/* // TODO: apply font family */}
                  <ColorCode colorsArray={colorsTextArray} colors={brand.colors.text} setColors={text => setBrand({...brand, colors:{...brand.colors, text}})} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <TextEditor />
        <div className='brand_setting_cta'>
          <CustomButton title="Cancel" type="Outlined"/>
          <CustomButton title="Save Changes"/>
        </div>
    </div>
    }
    </div>
  );
};

export default BrandSetting;
