import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Icons from "./assets/imageUrl";
import "./styles/workSpace.css";
import Modal from "react-bootstrap/Modal";
import { useQuery } from "react-query";
import Resource from "./api/resource";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import { deleteGroup, getGroups } from "./api/groups";
import { useEffect } from "react";
import CustomButton from "./Components/core/Button";
import PlusIcon from "./assets/plus-icon.svg";

const resource = Resource("workflows");
const WorkFlow = () => {

  // const { isLoading, isError, data } = useQuery(
  //   "workflows",
  //   async () => await resource.getAll(0, 5, "id,name,status")
  // );


  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [isMoreRecords, setIsMoreRecords] = useState(true);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("desc");
  const [selectedOption, setSelectedOption] = useState("date_asc");
  const [deleteModal, setDeleteModal] = useState(false)
  const [workflowId, setWorkflowId] = useState(null)


  const handleScroll = () => {
    if (loading || !isMoreRecords) {
      return;
    }
    if (isMoreRecords === true) {
      setLoading(true);
      getGroups(10, skip, search, sortOrder, sortBy)
        .then((res) => {
          if (res && res.length > 0) {
            setGroups((prevGroups) => prevGroups.concat(res));
            setIsMoreRecords(res.length === 10);
            setSkip(skip + 10);
          } else {
            setLoading(false);
            setIsMoreRecords(false);
          }
        })
        .catch((_err) => {
          console.log("Error :", _err)
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleDeleteWorkflow = () => {
    if (workflowId) {
      const groupIndex = groups.findIndex((group) => group.id === workflowId);

      if (groupIndex !== -1) {
        // Optimistic update: Remove the group immediately from the UI
        const updatedGroups = [...groups];
        updatedGroups.splice(groupIndex, 1);
        setGroups(updatedGroups);
      }

      // Send deletion request to the server
      deleteGroup(workflowId)
        .then(() => {
          setDeleteModal(false);
          // You can show a success notification here
        })
        .catch((error) => {
          console.error("Error deleting workflow:", error);
          // Handle error and potentially revert the optimistic update
        });
    }
  };

  // useEffect(() => {
  //   handleScroll();
  //   // Attach scroll event listener here
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     // Remove scroll event listener when component unmounts
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [skip]);




  const fetchGroups = (sk = skip) => {
    getGroups(10, sk, "", sortOrder, sortBy)
      .then((res) => {
        if (res && res.length > 0) {
          setGroups(res);
          setLoading(false);
          setSkip(10);
          setIsMoreRecords(true);
        } else {
          setLoading(false);
        }
      })
      .catch((_err) => {
        toast.error("Unable to fetch workflows data");
        setLoading(false);
      });
  };

  const handleSearchEnter = (event) => {
    // searchGroups
    if (event.key === "Enter") {
      if (search.length > 0) {
        setLoading(true);
        getGroups(10, 0, search, sortOrder, sortBy)
          .then((res) => {
            setGroups(res);
            setLoading(false);
          })
          .catch((_err) => {
            toast.error("Unable to fetch workflows data");
            setLoading(false);
          });
      }
    }
  };

  const handleSort = (event) => {
    const value = event;
    let sortOrder, sortBy;
    setSelectedOption(value);

    switch (value) {
      case "date_desc":
        sortOrder = "desc";
        sortBy = "created_at";
        break;
      case "date_asc":
        sortOrder = "asc";
        sortBy = "created_at";
        break;

      case "name_asc":
        sortOrder = "asc";
        sortBy = "name";
        break;

      case "name_desc":
        sortOrder = "desc";
        sortBy = "name";
        break;

      default:
        sortOrder = "desc";
        sortBy = "created_at";
    }

    setSortOrder(sortOrder);
    setSortBy(sortBy);

    setLoading(true);
    getGroups(10, 0, search, sortOrder, sortBy)
      .then((res) => {
        setGroups(res);
        setLoading(false);
      })
      .catch((_err) => {
        toast.error("Unable to fetch workflows data");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  // useEffect(() => {
  //   if (search.length === 0) {
  //     fetchGroups(0);
  //   }
  // }, [search])



  /* ==================================Select Work-flow Modal===Start=================================== */

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="select_workflows_modal"
      >
        <Modal.Body>
          <div className="-fluid wf-main-pad">
            <div className="row">
              <div className="col-lg-12 content_wrapper">
                <h3 className="wf-main-head">Select Your Workflow</h3>
                <p className="wf-desc">
                  Kindly choose the workflow category that best suits your needs in order to start building your workflow.
                </p>
              </div>
              <div className="col-lg-12 p-0">
                <div className="select_workflows_wrapper row">
                  <div className="select-workflow col-4">
                    <Link to={"new?type=api"}>
                      <img
                        src={Icons?.selectApi}
                        alt="image"
                        className="workflow-img"
                      />
                      <p className="card-heading">API Workflow</p>
                    </Link>
                  </div>
                  <div className="select-workflow col-4">
                    <Link to={"new?type=whatsapp"}>
                      <img
                        src={Icons?.selectWhatapp}
                        alt="image"
                        className="workflow-img"
                      />
                      <p className="card-heading">WhatsApp Workflow</p>
                    </Link>
                  </div>
                  <div className="select-workflow col-4">
                    <Link to={"new?type=web"}>
                      <img
                        src={Icons?.selectWebBased}
                        alt="image"
                        className="workflow-img"
                      />
                      <p className="card-heading">Web-based Workflow</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="workSpace">
        <section className="row">
          <div className="col-lg-12 WorkflowAlign">
            <main>
              <div className="workSpaceTitle">
                <p className="title_txt">Workspace</p>
                <p className="sub_txt">Browse all the existing workflows here</p>
              </div>
              <main className="row">
                <InfiniteScroll
                  dataLength={groups.length}
                  next={handleScroll}
                  hasMore={isMoreRecords || loading}
                  scrollThreshold={"200px"}
                  className="row workspace_wrapper"
                  loader={
                    <>
                      {
                        loading === true ? ([0, 1, 2, 3, 4, 5, 6, 7, 8].map((e, id) => (<Loader key={id} />))) : ""
                      }
                    </>
                  }
                >
                  <>
                    {
                      groups && groups.length > 0 ?
                        (
                          <>
                          
 

                          {/* <svg class="react-flow__background" data-testid="rf__background"><pattern id="pattern-1undefined" x="24.5" y="-33.5" width="40" height="40" patternUnits="userSpaceOnUse" patternTransform="translate(-4,-4)"><path stroke="#000" stroke-width="1" d="M4 0 V8 M0 4 H8"></path></pattern><rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-1undefined)"></rect></svg> */}
                            <div className="col-lg-4 col-xl-3 col-md-6 workspace_card_wrapper">
                              <div className="WorkSpaceDefault">
                                <div className="text_wrapper">
                                  <div className="text-center">
                                    <img
                                      src={Icons?.WorkSpaceEmty}
                                      alt="workspace"
                                      className="WorkspaceImg"
                                    />
                                  </div>
                                  <h5 className="text-center title_txt">
                                    {loading ? <Skeleton /> : "New Workflow"}
                                  </h5>
                                  <div className="text-center sub_txt">
                                    <p>
                                      {loading ? (
                                        <Skeleton count={2} />
                                      ) : (
                                        "Create new workflow according to your business needs"
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="text-center btn_wrapper">
                                    <CustomButton
                                      title="Create New Workflow"
                                      onClick={() => setModalShow(true)}
                                      isLeft={true}
                                      icon={PlusIcon}
                                    />
                                    {/* {loading ? (
                                      <Skeleton count={1} />
                                    ) : (
                                      "Create New Workflow"
                                    )} */}
                                </div>
                              </div>
                            </div>
                            {groups.map((workflow, i) => (
                              <>
                                <div
                                  className="col-lg-4 col-xl-3 col-md-6 workspace_card_wrapper"
                                  key={i + 1}
                                  onClick={() => navigate(workflow?.id)}
                                >
                                  <div className="WorkSpaceDynamic">
                                    <div className="text-center">
                                      <div className="d-flex justify-content-center align-items-center ImgWorkflowsection">
                                        {/* <div className="img_workspace">
                                          <img
                                            src={Icons?.WorkSpaceEmty}
                                            alt="workspace"
                                          />
                                        </div> */}
                                        <div className="img_connectLogo">
                                          <img
                                            src={Icons?.nodeConnectLogoGroup}
                                            alt="connectLogoGroup"
                                            className=""
                                          />
                                        </div>
                                        {/* <div className="img_workspace">
                                          <img
                                            src={Icons?.WorkSpaceEmty}
                                            alt="workspace"
                                          />
                                        </div> */}
                                      </div>
                                    </div>
                                    <section className="DynamicPartTwo">
                                      <h5>{workflow?.name}</h5>
                                      <div>
                                        <p>
                                          Lorem ipsum dolor sit amet consectetur.
                                          kfsf sifsfisf sifsf sfsfsf
                                        </p>
                                      </div>
                                      <section className="Dynamicflowbottom">
                                        <div>
                                          <button>{workflow?.status}</button>
                                        </div>
                                        <div className="Dynamicflowbottombuttons">
                                          <div>
                                            <CustomButton
                                              title="Play"
                                              isLeft={false}
                                              type="Outlined"
                                              icon={Icons?.playLogoArrow}
                                              onClick={e => {
                                                e.stopPropagation();
                                                navigate(`${workflow?.id}/preview`);
                                              }}
                                            />
                                          </div>
                                          <div className="options_div">
                                            <img src={Icons?.ThreeDots}alt="ThreeDots"/>
                                            <div className="dropdown_menu">
                                              <ul>
                                                <li onClick={
                                                  (e) => {
                                                    e.stopPropagation();
                                                    setWorkflowId(workflow?.id),
                                                    setDeleteModal(true)
                                                  }
                                                }>
                                                  <img src={Icons?.Trash14}alt="delete"/> <span>Delete</span>
                                                </li>
                                                {/* <li>
                                                  <img src={Icons?.Trash14}alt="delete"/> <span>Duplicate</span>
                                                </li> */}
                                                <li>
                                                  <img src={Icons?.ExportIcon}alt="delete"/> <span>Export</span>
                                                </li>
                                              </ul>
                                            </div>
                                            {/* <button
                                              onClick={
                                                () => {
                                                  setWorkflowId(workflow?.id),
                                                    setDeleteModal(true)
                                                }
                                              }
                                            >
                                            </button> */}
                                          </div>
                                        </div>
                                      </section>
                                    </section>
                                  </div>
                                </div>
                              </>
                            ))
                            }
                          </>
                        )
                        :
                        (
                          <>
                            {loading === false && <div className="workSpaceEmpty">
                              <div className="icon_div">
                                <img src={Icons?.WorkSpaceEmty} alt="workspace" />
                              </div>
                              <h3 className="title_txt">
                                You don't have any work flows
                              </h3>
                              <p className="sub_txt">
                                With intuitive drag-and-drop workflow builder, Seamlessly integrate APIs, reduce development time, and launch faster.
                              </p>
                              <button
                                type="button"
                                onClick={() => setModalShow(true)}
                              >
                                Create new work flow
                              </button>
                            </div>
                            }
                          </>
                        )}
                  </>
                </InfiniteScroll>
              </main>
            </main>
          </div>
        </section>
      </div>
      {
        deleteModal &&
        <>
          <div>
            <Modal
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={deleteModal}
              onHide={() => setDeleteModal(false)}

            >

              <Modal.Body className="confirm-modal-body">
                <h3 className="">Are you sure?</h3>
                <p>
                  You want to go delete the account you will loose all the data that are saved?
                </p>
                <div className="confirm-btns">
                  <button className="conf-cancel" onClick={handleDeleteWorkflow}>Yes, Delete</button>
                  <button className="conf-conf" onClick={() => setDeleteModal(false)}>No, don't delete</button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </>
      }
    </>
  );
};

const Loader = () => (
  <div className="col-lg-4 col-xl-3 col-md-6">
    <div className="workflow-builder-card-sekelton">
      <div className="top-skeleton-container">
        <Skeleton count={1} width={60} height={50} />
        <Skeleton count={1} width={50} height={3} />
        <Skeleton count={1} width={60} height={50} />
      </div>

      <div className="skeleton-card-title">
        <Skeleton count={1} width={70} height={10} />
      </div>
      <div className="skeleton-card-body">
        <Skeleton count={1} width={"100%"} height={10} />
        <Skeleton count={1} width={"100%"} height={10} />
        <Skeleton count={1} width={"100%"} height={10} />
      </div>
      <div className="bottom-skelton-container">
        <Skeleton width={70} height={30} />
        <Skeleton width={70} height={30} />
      </div>
    </div>
  </div>
);

export default WorkFlow;
