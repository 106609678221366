import AirTable from "./Airtablelogo.png";
import Atlassian from "./Atlassian.png";
import Highlight from "./Highlight.png";
import DocIcon from "./Icon.svg";
import backwardArrow from "./Line-Arrow-Left.svg";
import chatIcon from "./chat-icon.svg";
import closeBtn from "./closebtn.svg";
import dashboardIcon from "./dashboard-icon.svg";
import Icon from "./docIcon.svg";
import downArrow from "./downArrow.svg";
import leftArrow from "./ep_back.svg";
import Evalution from "./evalutionIcon.png";
import explore from "./explore-icon.svg";
import flowIcon from "./flow-icon.svg";
import playFlow from './flow-play-logo.svg';
import flowIcon1 from "./flowbuilderIcon1.svg";
import flowIcon2 from "./flowbuilderIcon2.svg";
import flowIcon3 from "./flowbuilderIcon3.svg";
import idFlowLogo from "./idflow-logo.svg";
import minusIcon from "./minus_icon.js";
import nodeConnectLogo from './node-connecting-logo.svg';
import nodeConnectLogoGroup from './node-connecting-logo-group.svg';
import SMSNodeLogo from './node-icons/SMSNodeLogo.svg';
import { default as apiCallNodeLogo, default as webHookNodeLogo } from './node-icons/apiCallNodeLogo.svg';
import backendApi from "./node-icons/backendapi.svg";
import buttonNodeLogo from './node-icons/buttonNodeLogo.svg';
import chatNodeLogo from './node-icons/chatNodeLogo.svg';
import conditionNodeLogo from "./node-icons/conditionNodeLogo.svg";
import deleteModelImg from './node-icons/deleteModelImg.svg';
import emailNodeLogo from './node-icons/emailNodeLogo.svg';
import listNodeLogo from './node-icons/listNodeLogo.svg';
import messageNodeLogo from './node-icons/messageNodeLogo.svg';
import notificationNodeLogo from './node-icons/notificationNodeLogo.svg';
import penModelImg from './node-icons/penModelImg.svg';
import questionNodeLogo from './node-icons/questionNodeLogo.svg';
import updateNodeLogo from './node-icons/updateNodeLogo.svg';
import validationIcon from './node-icons/validationIcon.svg';
import zapierNodeLogo from './node-icons/zapierNodeLogo.svg';
import nodesBuilder from "./nodes-builder.png";
import playLogoArrow from './play-logo-arrow.svg';
import play from "./play.svg";
import plusIcon from "./plus_icon.js";
import profileImage from './profile-image.svg';
import profileLogo from "./profile-logo.svg";
import SearchIcon from "./search.svg";
import selectWhatapp from "./selectWpflow.svg";
import selectApi from "./selectapiflow.svg";
import selectWebBased from "./selectwebflow.svg";
import undoArrow from './undoArrow.svg';
import WorkSpaceEmty from "./workspace-empty-logo.svg";
import uploadIcon from "./uploadIcon.svg"
import startNodeHome from './startNodeHome.svg'
import plusCircle from './plusCircle.svg'
// import amazonS3 from './amazonS3.svg'
import sidePanelCondition from './sidePanelImg/condition.svg'
import sidePanelConversion from './sidePanelImg/conversion.svg'
import sidePanelEmail from './sidePanelImg/Email.svg'
import sidePanelMatrix from './sidePanelImg/matrix.svg'
import sidePanelNotification from './sidePanelImg/Notification.svg'
import sidePanelPage from './sidePanelImg/page.svg'
import sidePanelSMS from './sidePanelImg/SMS.svg'
import sidePanelUpdate from './sidePanelImg/update.svg'
import sidePanelZapier from './sidePanelImg/zapier.svg'
import sidePanelAPICall from './sidePanelImg/APICall.svg'
import sidePanelChat from './sidePanelImg/Chat.svg'
import Trash14 from "./trash14.svg"
import desktopNodeIcon from './node-icons/desktop_windows.svg';
import sidePanelButton from './sidePanelImg/Button.svg'
import sidePanelMessage from './sidePanelImg/Message.svg'
import sidePanelQuestion from './sidePanelImg/Question.svg'
import sidePanelList from './sidePanelImg/List.svg'
import accessMenuToggle from './accessMenuToggle.svg'
import sidePanelWebhook from './sidePanelImg/Webhook.svg'
import sidePanelAWSBucket from './sidePanelImg/AwsBucket.svg'
import sidePanelTimeDelay from './sidePanelImg/TimeDelay.svg'
import sidePanelTimeRandom from './sidePanelImg/Random.svg'
import ThreeDots from "./three_dots_icon.svg"
import LineArrowChevronRight from "./Line-Arrow-Chevron-Right.svg"
import ExportIcon from "./export-icon.svg"
import AWSColor from './nodeColorIcon/AWSColor.svg';
import randomColor from './nodeColorIcon/randomColor.svg';
import timeDelayColor from './nodeColorIcon/timeDelayColor.svg';
import webHookColor from './nodeColorIcon/webHookColor.svg';
export { 
  AWSColor,
  randomColor,
  webHookColor,
  timeDelayColor,
  deleteModelImg,
    desktopNodeIcon,
   penModelImg,
   validationIcon,
   sidePanelCondition,
   sidePanelConversion,
   sidePanelEmail,
   sidePanelMatrix,
   sidePanelNotification,
   sidePanelPage,
   sidePanelSMS,
   sidePanelUpdate,
   sidePanelZapier,
   sidePanelAPICall,
   sidePanelChat,
   sidePanelButton,
   sidePanelMessage,
   sidePanelList,
   accessMenuToggle,
   sidePanelQuestion,
   sidePanelWebhook,
   sidePanelAWSBucket,
   sidePanelTimeDelay,
   sidePanelTimeRandom,
  };
// export { deleteModelImg, penModelImg, validationIcon };
const Icons = {
  questionNodeLogo,
  listNodeLogo,
  messageNodeLogo,
  buttonNodeLogo,
  SMSNodeLogo,
  emailNodeLogo,
  notificationNodeLogo,
  chatIcon,
  idFlowLogo,
  dashboardIcon,
  flowIcon,
  profileLogo,
  explore,
  profileImage,
  WorkSpaceEmty,
  playFlow,
  nodeConnectLogo,
  nodeConnectLogoGroup,
  playLogoArrow,
  selectApi,
  selectWebBased,
  selectWhatapp,
  closeBtn,
  leftArrow,
  play,
  flowIcon1,
  flowIcon2,
  flowIcon3,
  Atlassian,
  AirTable,
  SearchIcon,
  Icon,
  Highlight,
  Evalution,
  DocIcon,
  downArrow,
  backendApi,
  nodesBuilder,
  conditionNodeLogo,
  apiCallNodeLogo,
  updateNodeLogo,
  zapierNodeLogo,
  webHookNodeLogo,
  chatNodeLogo,
  plusIcon,
  minusIcon,
  backwardArrow,
  undoArrow,
  uploadIcon,
  startNodeHome,
  plusCircle,
  Trash14,
  ThreeDots,
  LineArrowChevronRight,
  ExportIcon,
    desktopNodeIcon,
  // amazonS3
};

export default Icons;
