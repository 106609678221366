import React, { useState, useEffect } from "react";
import { AiFillMessage } from "react-icons/ai";
import { NodeFooter, NodeHeader, StartNode } from "../../Common";
import axios from "axios";
import FormData from "form-data";
import { useUpdateNodeInternals } from "reactflow";
import { createFile, fullPath as fileFullPath } from "../../../../../api/files";
import Icons from "../../../../../assets/imageUrl";
import SidePanelClose from "../../../../../Components/sidePanelClose/SidePanelClose";
import { useUpdateNode } from "../../../../hooks";
import { useNodeData } from "../../../../store/selector";
const MEDIA_TYPES = ["image", "audio", "video"];

export function MessagePanel({
  id,
  children,
  message = true,
  type = "Message",
  includeVariable = true,
}) {
  const [data, setData] = useNodeData(id);

 
  // const setData = setData;
  console.log("data loaded", data);
  const [fileType, setFileType] = useState("");
  // const [imgfileName, setImgfileName] = useState("")
console.log(fileType,data.file,"Get the podding")
  if (data.file === undefined) {
    setData({ ...data, file: { caption: "", id: "" } });
  }

  return (
    <div className="right_panel_workflow">
      {/* {data.file.mediaType === "image" || "video" || "audio" ? setFileType(true) : undefined} */}
      {/* <label className="right_section_separate side-label top-right m-0">
        {type}
      </label> */}
        <SidePanelClose nodeName="Message" />
        <section className="sideInnerParent">

       
        <div>
            <label className="nodePanel-labels">{type + " Name"}</label>

  <div className="labelInputFull">
  <input
              type="text"
              value={data.name || ""}
              maxLength={15}
              onChange={(e) => setData({ ...data, name: e.target.value })}
              placeholder={type + " Name"}
            />
  </div>
      
        </div>

        <div className="msgType">
        
            <label className="nodePanel-labels">Choose Type</label>
    
          <div className="radioCommon">
            
              <label>
                <input
                  type="radio"
                  checked={data.type === "text"}
                  onChange={() => setData({ ...data, type: "text" })}
                  className={" "}
                />
                <span>Text</span>
              </label>

              <label>
                <input
                  type="radio"
                  checked={data.type === "media"}
                  onChange={() => setData({ ...data, type: "media" })}
                  className={" "}
                />
                <span>Media</span>
              </label>
            </div>

            <section className="inputsVarType">
            {data.type === "text" ? (
          < >
             <label className="nodePanel-labels">Description</label>
             <div className="msgTextArea">
             <textarea
                
                  placeholder="Description text box"
                  id="floatingTextarea2"
                  value={data.text}
                  onChange={(e) => {
                    setData({ ...data, text: e.target.value });
                  }}
                ></textarea>
             </div>
          </>
        ) : (
          <></>
        )}
          {data.type === "media" ? (
          <>
                  <label className="nodePanel-labels">Select Media Type</label>
<div className="labelInputFull">
<select
                  onChange={(e) => {
                    setData({
                      ...data,
                      file: { ...data.file, mediaType: e.target.value },
                    });
                    setFileType(e.target.value);
                  }}
                 
                >
                  <option value="select" selected disabled>
                    select
                  </option>
                  {MEDIA_TYPES.map((type) => {
                    return (
                      <option
                        value={type}
                        selected={data.file.mediaType === type}
                      >
                        {type}
                      </option>
                    );
                  })}
                </select>
</div>
               
             

              <div className="radioCommon">
                <label>
                  {/* <span className="side_label">URL</span>
                  <input
                    type="radio"
                    checked={data.file.type === "url"}
                    onChange={() =>
                      setData({
                        ...data,
                        file: { ...data.file, type: "url" },
                      })
                    } 
                  /> */}
                  <input
                    type="radio"
                    checked={data.file.type === "url"}
                    onChange={() =>
                      setData({
                        ...data,
                        file: { ...data.file, type: "url" },
                      })
                    }
                    className={" " }
                  />
                  <span>URL</span>
                </label>

                <label >
                  <input
                    type="radio"
                    checked={data.file.type === "id"}
                    onChange={() =>
                      setData({
                        ...data,
                        file: { ...data.file, type: "id" },
                      })
                    }
                    className={" " }
                  />
                  <span>Id</span>
                </label>

                <label >
                  <input
                    type="radio"
                    checked={data.file.type === "upload"}
                    onChange={() =>
                      setData({
                        ...data,
                        file: { ...data.file, type: "upload" },
                      })
                    }
                    className={" "}
                  />
                  <span>Upload</span>
                </label>
              </div>
           
            {data.file.type === "id" ? (
              <div className="labelInputFull">
                <input
                  type="text"
                  value={data.file.id}
                
                  placeholder="Enter File Id"
                  onChange={(e) => {
                    setData({
                      ...data,
                      file: { ...data.file, id: e.target.value },
                    });
                  }}
                />
              </div>
            ) : (
              <></>
            )}
            {data.file.type === "url" ? (
              <div className="labelInputFull">
                <input
                  type="text"
                
                  value={data.file.url}
                  placeholder="Enter url"
                  onChange={(e) => {
                    setData({
                      ...data,
                      file: { ...data.file, url: e.target.value },
                    });
                  }}
                />
              </div>
            ) : (
              <></>
            )}
            {data.file.type === "upload" ? (
              <>
            
{/*                
                    <label className="nodePanel-labels">Select Media Type</label>
                 
<div className="labelInputFull">
<select
                    onChange={(e) => {
                      setData({
                        ...data,
                        file: {
                          ...data.file,
                          mediaType: e.target.value,
                        },
                      });
                      setFileType(e.target.value);
                    }}
                    value={data.mediaType}
                   
                  >
                    <option value="select" selected disabled>
                      select media type
                    </option>
                    {MEDIA_TYPES.map((type) => {
                      return (
                        <option
                          value={type}
                          selected={data.file.mediaType === type}
                        >
                          {type}
                        </option>
                      );
                    })}
                  </select>
                  {fileType ||
                  data.file.mediaType === "image" ||
                  "video" ||
                  "audio" ? (
                  <div className="selectFileInput">
                    <label for="file"> <img src={Icons.uploadIcon} alt="" /> Upload file</label>
                                        <input
                    
                      type="file"
                      id="file"
                     
                      accept={fileType + "/*"}
                      onChange={(e) => {
                        let form_data = new FormData();
                        form_data.append("file", e.target.files[0]);
                        createFile(form_data, (response) => {
                          console.log("rr pint");
                          console.log(response.data);
                          setData({
                            ...data,
                            file: {
                              ...data.file,
                              id: response.data.id,
                              type: "id",
                            },
                          });
                        });
                      }}
                      disabled={fileType ? false : true}
                    // placeholder={fileType ? imgfileName : "chose file"}

                    // disabled="false"
                    />
                  <button>Clear</button>
                   </div>
                ) : // console.log("Set")
                  undefined}
</div> */}
                  {
                  data?.file?.mediaType === "image" ||
                  "video" ||
                  "audio" ? (
                  <div className="selectFileInput">
                    <label for="file"> <img src={Icons.uploadIcon} alt="" /> Upload file</label>
                                        <input
                    
                      type="file"
                      id="file"
                     
                      accept={fileType + "/*"}
                      onChange={(e) => {
                        let form_data = new FormData();
                        form_data.append("file", e.target.files[0]);
                        createFile(form_data, (response) => {
                          console.log("rr pint");
                          console.log(response.data);
                          setData({
                            ...data,
                            file: {
                              ...data.file,
                              id: response.data.id,
                              type: "id",
                            },
                          });
                        });
                      }}
                      disabled={fileType ? false : true}
                    // placeholder={fileType ? imgfileName : "chose file"}

                    // disabled="false"
                    />
                  <button>Clear</button>
                   </div>
                ) : // console.log("Set")
                  undefined}

                  {/* {fileType ? <></> : <p>Select media type</p>} */}
               
                
              </>
            ) : (
              <></>
            )}

            {/* {fileType ?<>Hello world!</>:<>Bad world</>} */}
            {/* {data.file.type === "uploadfile" ?<>Hello world!</>:<>Bad world</>} */}
            {["id", "url"].indexOf(data.file.type) !== -1 ? (
              <>
                {/* <img
                  className="file-img"
                  height={100}
                  width={100}
                  src={
                    data.file.type === "url"
                      ? data.file.url
                      : fileFullPath(data.file.id)
                  }
                  alt="File"
                /> */}
              </>
            ) : (
              <></>
            )}
            <label className="nodePanel-labels" htmlFor="">Caption</label>
            <div className="msgTextArea">
              <textarea
                placeholder="Leave a caption here"
                id="floatingTextarea2"
                value={data.file.caption}
                onChange={(e) => {
                  setData({
                    ...data,
                    file: { ...data.file, caption: e.target.value },
                  });
                }}
              ></textarea>
            </div>
          </>
        ) : (
          <></>
        )}
            </section>
        </div>
        
      
        {includeVariable && (
          <div >
              <label className="nodePanel-labels" >Variable</label>
<div className="labelInputFull">
<input
              
              placeholder="Variable"
              id="floatingTextarea3"
              value={data.variable}
              onChange={(e) => {
                setData({
                  ...data,
                  variable: e.target.value,
                });
              }}
            ></input>
            {message && (
          <StartNode
            isStartNode={data.immediateReply}
            setIsStartNode={() =>
              setData({
                ...data,
                immediateReply: !(data.immediateReply || false),
              })
            }
            text="Immediate Reply"
          />
        )}
</div>
           
          </div>
        )}

        {children}

        
       </section>
    </div>
  );
}

const MAX_CHARS = 20;
export default function MessageNode({
  id,
  data,
  name = "Message",
  icon,
  children,
  selected,
  includeFooter = true,
  style
}) {
  let pane = null;


  if (data.type === "text") {
    let text = data.text || "";
    pane = text.length > 1 && (
      <p >
        {text.length > MAX_CHARS
          ? text.substring(0, MAX_CHARS).concat(". . .")
          : text}{" "}
      </p>
    );
  } else if (data.type === "media") {
    let file = data.file || {};
    pane = (
      <>
        {file.type === "id" && (
          <p >
            {file.id.length > MAX_CHARS
              ? file.id.substring(0, MAX_CHARS).concat(". . .")
              : file.id}
          </p>
        )}
        {file.caption && (
          <p >
            {" "}
            {file.caption.length > MAX_CHARS
              ? file.caption.substring(0, MAX_CHARS).concat(". . .")
              : file.caption}
          </p>
        )}
      </>
    );
  }
  return (
    <div
      className={
        "App "
        // +
        // (data.isStartNode ? "start_node" : "") +
        // (!data.isStartNode && selected ? "selected_node" : "")
      }
    >
      <div style={style ? style : { backgroundColor: "#e8f4ff", border: selected ? `solid 2px ${"#81d3ff"}` : "" }}
           className={`${"set_msgcard"} ${"commonHeader"}`}>
        <div></div>

        <NodeHeader
          name={name}
          onDelete={data.delete}
          icon={icon ? icon : Icons.messageNodeLogo}
          isStartNode={data.isStartNode}
          accessSelected={selected}
          
        />

        <div className="messageNodeStyle">
          {/* <div className="paneText">
            <p>{data.name}</p>
            </div> */}
          {pane && <div className="beginSivStyle">
            {pane}
            {includeFooter ? <NodeFooter /> : <></>}
          </div>}

          {children && children}
        </div>
      </div>

    </div>
  );
}



// const ImgName = event.target.files[0].name;
//     setImgfileName(ImgName);