import {useCallback, useEffect, useRef} from "react";
import APICall from "./Flow/Nodes/Common/APICall/Apicall";
import UpdateVariable from "./Flow/Nodes/Common/UpdateVariable/UpdateVariable";
import Condition from "./Flow/Nodes/Common/Condition/Condition";
import { ZapierNode } from "./Flow/Nodes/Common/zapier/zapiertrigger";
import { commonNodes, communicationNodes } from "./DefaultNodeItems";
import { NodePanel } from "./NodePanel";
import { NovuNode } from "./Flow/Nodes/Common/Novu/NovuPanel";
import ExecuteAPINode from "./Flow/Nodes/Common/ExecuteAPI/ExecuteAPIPanel";
import { Webhook } from "./Flow/Nodes/API/Webhook/webhook";
import Conversion from "./Flow/Nodes/Common/Conversion/Conversion";
import Matrix from "./Flow/Nodes/Common/Matrix/Matrix";
import {Background, Controls, MarkerType, ReactFlow, useOnViewportChange} from "reactflow";
import "../styles/Flow.css";
import MessageNode from "./Flow/Nodes/whatsapp/Message/Message";
import QuestionNode from "./Flow/Nodes/whatsapp/Question/Question";
import InteractiveButtons from "./Flow/Nodes/whatsapp/InteractiveButtons/InteractiveButtons";
import InteractiveList from "./Flow/Nodes/whatsapp/InteractiveList/InteractiveList";
import PageNode from "./Flow/Nodes/Pages/Page/page";
import useFlow, { selector } from "./store/flowStore";
import { shallow } from "zustand/shallow";
import { useOutletContext } from "react-router-dom";
import { StartNode } from "./Flow/Nodes/Common/StartNode/startNode";
import AwsS3 from "./Flow/Nodes/Aws S3/AwsS3";
import RandomNode from "./Flow/Nodes/Common/RandomNode/RandomNode";
import TimeDelayNode from "./Flow/Nodes/Common/TimeDelay/TimeDelay";
import SwitchNode from "./Flow/Nodes/Common/SwitchNode/SwitchNode";
import {sidePanelTimeRandom} from '../assets/imageUrl'
import * as _ from "lodash";

const nodeTypes = {
  StartNode: StartNode,
  Webhook: Webhook,
  Condition: Condition,
  APICall: APICall,
  UpdateVariable: UpdateVariable,
  Trigger: ZapierNode,
  Novu: NovuNode,
  ExecuteAPI: ExecuteAPINode,
  Conversion: Conversion,
  Matrix: Matrix,
  Message: MessageNode,
  Question: QuestionNode,
  InteractiveButtons: InteractiveButtons,
  InteractiveList: InteractiveList,
  Page: PageNode,
  AwsS3: AwsS3,
  Random: RandomNode,
  TimeDelay: TimeDelayNode,
  SwitchNode:SwitchNode
};

const nodeItems = { commonNodes, communicationNodes };


const useViewPort = (rfInstance) =>{
  const viewport = useFlow(flow => flow.viewport);
  const setViewPort = useFlow(flow => flow.setViewPort);

  useEffect(() =>{
    if (rfInstance !== null){
      let oldViewport = rfInstance.getViewport();
      if (!_.isEqual(oldViewport, viewport)){
        console.log(oldViewport, viewport)
        rfInstance.setViewport(viewport)
      }
    }
  }, [viewport])


  useOnViewportChange({
    onChange: setViewPort,
    onStart: setViewPort,
    onEnd: setViewPort
  })

  return viewport
}
function Flow() {
  const { rfInstance, setRfInstance, isLoading } = useOutletContext();
  // const viewport  = useViewPort(rfInstance);
  const viewport = useFlow(flow => flow.viewport);
  const setViewPort = useFlow(flow => flow.setViewPort)

  useEffect(() =>{
    if (rfInstance !== null){
      rfInstance.setViewport(viewport);
    }
    return () =>{
      if (rfInstance !==null){
        setViewPort(rfInstance.getViewport())
      }
    }
  }, [rfInstance])

  useEffect(() =>{

  }, [viewport])

  const {
    nodes,
    onNodesChange,
    edges,
    onEdgesChange,
    onConnect,
    onDrop,
    onEdgeUpdate,
    onEdgeUpdateStart,
    onEdgeUpdateEnd
  } = useFlow(selector, shallow);

  const reactFlowWrapper = useRef(null);


  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  let dynamicEdges = edges
    .map((ed) => {
      ed["markerEnd"] =  {
        type: MarkerType.ArrowClosed,
        color: "#333434",
        width: 20,
        height: 20,
        strokeWidth: 1.5,
        

      };
      ed["type"]="step"
      
      return ed
    });
    // const defaultViewport = { x: 0, y: 0, zoom: 0};
  return (
    <>
      <div ref={reactFlowWrapper} className="main_wrap">
     
        <ReactFlow
            defaultViewport={viewport}
          nodes={nodes}
          onNodesChange={onNodesChange}
          edges={dynamicEdges}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          onConnect={onConnect}
          onInit={setRfInstance}
          snapToGrid={true}
          fitView={false}
          minZoom={0.5}
          onDrop={event => onDrop(event, reactFlowWrapper, rfInstance)}
          onDragOver={onDragOver}
          proOptions={{
            hideAttribution: "true"
          }}
          onEdgeUpdate={onEdgeUpdate}
          onEdgeUpdateStart={onEdgeUpdateStart}
          onEdgeUpdateEnd={onEdgeUpdateEnd}
        >
          <Background variant="dots"  gap={18} color="gray" size={1} />
          <Controls />
        </ReactFlow> 
        
      </div>
      <NodePanel />
    </>
  );
}

export default Flow;
