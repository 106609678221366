import React, { useState, KeyboardEventHandler, useEffect } from "react";
import { NodeFooter, NodeHeader, StartNode } from "../../Common";
import { BsFillChatLeftTextFill } from "react-icons/bs";
// import CreatableSelect from "react-select/creatable";
import { JsonEditor } from "jsoneditor-react";
import Icons from '../../../../../assets/imageUrl';
import SidePanelClose from "../../../../../Components/sidePanelClose/SidePanelClose"
import {
  Ses,
  Apns,
  Sns,
  Discord,
  Expo,
  Fcm,
  Infobip,
  Mailersend,
  Mailgun,
  Mailjet,
  Mandrill,
  Netcore,
  Nexmo,
  Outlook,
  Postmark,
  Resend,
  SendGrid,
  Sendinblue,
  Slack,
  Sms77,
  SparkPost,
  Teams,
  Telnyx,
  Twilio,
} from "../../../../logourls";
import { useNodeData } from "../../../../store/selector";

const providersMap = {
  chat: {
    providers: ["ms-teams", "slack", "discord"],
    fields: [
      {
        name: "Webhook URL",
        key: "webhookUrl",
        type: "url",
        placeholder: "https//webhookurl.com/something",
      },
      {
        name: "Content",
        key: "content",
        type: "textArea",
        placeholder: "Message content",
      },
      {
        name: "Channel",
        key: "channel",
        type: "text",
        placeholder: "Channel",
      },
    ],
  },
  email: {
    providers: [
      "sendgrid",
      "ses",
      "sendinblue",
      "resend",
      "sparkpost",
      "postmark",
      "mailjet",
      "mailersend",
      "outlook365",
      "mailgun",
      "mandrill",
      "netcore",
      "infobip",
    ],
    fields: [
      {
        name: "Mails *",
        key: "to",
        type: "email",
        multi: true,
        placeholder: "Enter mail",
      },
      {
        name: "HTML *",
        key: "hmtl",
        type: "text",
        placeholder: "Mail body here",
      },
      {
        name: "Subject *",
        key: "subject",
        type: "textArea",
        placeholder: "Subject",
      },
      {
        name: "Text",
        key: "text",
        type: "textArea",
        placeholder: "Text Here",
      },

      {
        name: "ReplyTo",
        key: "replyTo",
        type: "email",
        placeholder: "From",
      },
      {
        name: "CC",
        key: "cc",
        type: "email",
        multi: true,
        placeholder: "CC",
      },
      {
        name: "BCC",
        key: "bcc",
        type: "email",
        multi: true,
        placeholder: "BCC",
      }
    ],
  },
  notification: {
    providers: ["fcm"],
    fields: [
      {
        name: "To",
        key: "to",
        type: "email",
        placeholder: "Device Tokens",
        multi: true,
      },
      {
        name: "Payload Data",
        placeholder: "Payload Data",
        json: true,
      },
    ],
  },
  sms: {
    providers: ["twilio", "sms77", "nexmo", "sns", "telnyx"],
    fields: [
      {
        name: "To",
        key: "to",
        type: "text",
        placeholder: "To Address",
      },
      {
        name: "Content",
        key: "content",
        type: "textArea",
        placeholder: "Content & Message Body",
      },

    ],
  },
};

const Image = ({ src }) => {
  return <img style={{ height: "1.1rem" }} src={src} />;
};

const providersNamesMap = {
  "ms-teams": { name: "Microsoft Teams", image: <Image src={Teams} /> },
  slack: {
    name: "Slack",
    image: <Image src={Slack} />,
    auth: [
      {
        name: "ApplicationID *",
        key: "applicationID",
        type: "text",
        placeholder: "Application ID",
      },
      {
        name: "ClientID *",
        key: "clientID",
        type: "text",
        placeholder: "Client ID",
      },
      {
        name: "Secret Key *",
        key: "secret",
        type: "text",
        placeholder: "Secret Key",
      },
    ],
  },
  discord: { name: "Discord", image: <Image src={Discord} /> },
  sendgrid: {
    name: "SendGrid",
    image: <Image src={SendGrid} />,
    auth: [
      {
        name: "API Key *",
        key: "apiKey",
        type: "text",
        placeholder: "API Key",
      },
    ],
  },
  ses: {
    name: "Amazon SES",
    image: <Image src={Ses} />,
    auth: [
      {
        name: "Region *",
        key: "region",
        type: "text",
        placeholder: "Region",
      },
      {
        name: "Access Key ID *",
        key: "accessKeyId",
        type: "text",
        placeholder: "AccessKey ID",
      },
      {
        name: "Secret Access Key *",
        key: "secretAccessKey",
        type: "text",
        placeholder: "Secret Access Key",
      },
      {
        name: "From*",
        key: "from",
        type: "text",
        placeholder: "From Address",
      },
    ],
  },
  sendinblue: {
    name: "Sendinblue",
    image: <Image src={Sendinblue} />,
    auth: [
      {
        name: "API Key *",
        key: "apiKey",
        type: "text",
        placeholder: "API Key",
      },
    ],
  },
  resend: {
    name: "Resend",
    image: <Image src={Resend} />,
    auth: [
      {
        name: "API Key *",
        key: "apiKey",
        type: "text",
        placeholder: "API Key",
      },
    ],
  },
  sparkpost: { name: "SparkPost", image: <Image src={SparkPost} /> },
  postmark: {
    name: "Postmark",
    image: <Image src={Postmark} />,
    auth: [
      {
        name: "API Key *",
        key: "apiKey",
        type: "text",
        placeholder: "API Key",
      },
    ],
  },
  mailjet: {
    name: "Mailjet",
    image: <Image src={Mailjet} />,
    auth: [
      {
        name: "API Key *",
        key: "apiKey",
        type: "text",
        placeholder: "API Key",
      },
      {
        name: "API Secret *",
        key: "apiSecret",
        type: "text",
        placeholder: "API Secret Key",
      },
      {
        name: "From *",
        key: "from",
        type: "email",
        placeholder: "From Address",
      },
    ],
  },
  mailersend: {
    name: "MailerSend",
    image: <Image src={Mailersend} />,
    auth: [
      {
        name: "API Key *",
        key: "apiKey",
        type: "text",
        placeholder: "API Key",
      },
    ],
  },
  outlook365: {
    name: "Outlook 365",
    image: <Image src={Outlook} />,
    auth: [
      {
        name: "From *",
        key: "from",
        type: "email",
        placeholder: "From Address",
      },
      {
        name: "Sender Name *",
        key: "senderName",
        type: "text",
        placeholder: "Sender Name",
      },
      {
        name: "Password *",
        key: "password",
        type: "password",
        placeholder: "Password",
      },
    ],
  },
  mailgun: {
    name: "Mailgun",
    image: <Image src={Mailgun} />,
    auth: [
      {
        name: "API Key *",
        key: "apiKey",
        type: "text",
        placeholder: "API Key",
      },
      {
        name: "Domain *",
        key: "domain",
        type: "text",
        placeholder: "Domain",
      },
      {
        name: "Username *",
        key: "username",
        type: "text",
        placeholder: "Username",
      },
    ],
  },
  mandrill: {
    name: "Mandrill",
    image: <Image src={Mandrill} />,
    auth: [
      {
        name: "API Key *",
        key: "apiKey",
        type: "text",
        placeholder: "API Key",
      },
      {
        name: "From *",
        key: "from",
        type: "email",
        placeholder: "From",
      },
    ],
  },
  netcore: { name: "Netcore", image: <Image src={Netcore} /> },
  infobip: { name: "Infobip", image: <Image src={Infobip} /> },
  fcm: {
    name: "FCM", image: <Image src={Fcm} />,
    auth: [
      {
        name: "Auth Key *",
        key: "auth",
        type: "text",
        placeholder: "Auth Key",
      },
    ]
  },
  expo: { name: "Expo Push", image: <Image src={Expo} /> },
  apns: { name: "APNS", image: <Image src={Apns} /> },
  twilio: {
    name: "Twilio",
    image: <Image src={Twilio} />,
    auth: [
      {
        name: "Accounts ID *",
        key: "accountSid",
        type: "text",
        placeholder: "AccountS ID",
      },
      {
        name: "Auth Token *",
        key: "authToken",
        type: "text",
        placeholder: "Auth Token",
      },
      {
        name: "From *",
        key: "from",
        type: "email",
        placeholder: "e-mail",
      },
    ],
  },
  sms77: {
    name: "SMS 77",
    image: <Image src={Sms77} />,
    auth: [
      {
        name: "API Key *",
        key: "apiKey",
        type: "text",
        placeholder: "API Key",
      },
      {
        name: "From *",
        key: "from",
        type: "email",
        placeholder: "From",
      },
    ],
  },
  nexmo: {
    name: "Nexmo",
    image: <Image src={Nexmo} />,
    auth: [
      {
        name: "API Key *",
        key: "apiKey",
        type: "text",
        placeholder: "API Key",
      },
      {
        name: "API Secret *",
        key: "apiSecret",
        type: "text",
        placeholder: "API Secret Key",
      },
      {
        name: "From *",
        key: "from",
        type: "email",
        placeholder: "From Address",
      },
    ],
  },
  sns: {
    name: "AWS SNS",
    image: <Image src={Sns} />,
    auth: [
      {
        name: "Region *",
        key: "region",
        type: "text",
        placeholder: "Region",
      },
      {
        name: "Access Key ID *",
        key: "accessKeyId",
        type: "text",
        placeholder: "AccessKey ID",
      },
      {
        name: "Secret Access Key *",
        key: "secretAccessKey",
        type: "text",
        placeholder: "Secret Access Key",
      },
    ],
  },
  telnyx: {
    name: "Telnyx",
    image: <Image src={Telnyx} />,
    auth: [
      {
        name: "API Key *",
        key: "apiKey",
        type: "text",
        placeholder: "API Key",
      },
      {
        name: "Message Profile ID *",
        key: "messageProfileId",
        type: "text",
        placeholder: "Message Profile ID",
      },
      {
        name: "From *",
        key: "from",
        type: "email",
        placeholder: "From Address",
      },
    ],
  },
};

export const NovuPanel = ({ id }) => {
  const [data, setData] = useNodeData(id);

  // const [provider, setProvider] = useState(true);
  const updateData = setData;

  let type = data.type.toLowerCase();
  const [mails, setMails] = useState("");
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);


  const createOption = (label) => ({
    label,
    value: label,
  });
  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  }


  return (
    <div className="right_panel_workflow">
      <SidePanelClose nodeName={data.type} />
      <div className="sideInnerParent">
  
        <div>
      
            <label className="nodePanel-labels">{type + " Name"}</label>
<div className="labelInputFull">
<input
              type="text"
              className="nodePanel-inputs mb-2"
              value={data.name || ""}
              maxLength={15}
              onChange={(e) => updateData({ ...data, name: e.target.value })}
              placeholder={type + " Name"}
            />
     
</div>
          
        </div>
        <div >
          <label class="nodePanel-labels">Providers</label>
<div className="labelInputFull">
<select
            className="nodePanel-inputs mt-2"
            onChange={(e) => updateData({ ...data, provider: e.target.value })}
            value={data.provider}
          >
            <option selected disabled>
              Select Provider
            </option>

            {providersMap[type].providers.map((provider) => (
              <option value={provider}>{providersNamesMap[provider].name}</option>
            ))}
          </select>
</div>
          
        </div>

        <div>
        {/* Fields for Type ( Chat / SMS / Notification / Email*/}
        {(providersMap[type].fields || []).map((field) => {
          let multi = field.multi || false;
          let json = field.json || false;



          return (
            <>
              <label class="nodePanel-labels">{field.name || field.key}</label>

              {field.type === "textArea" ? (
                <div className="msgTextArea">
                  <textarea
                    className="nodePanel-inputs"
                    placeholder="Enter Text Here"
                    value={(data.fields || {})[field.key]}
                    onChange={(e) =>
                      updateData({
                        ...data,
                        fields: {
                          ...data.fields,
                          [field.key]: e.target.value,
                        },
                      })
                    }
                  ></textarea>
                </div>
              ) : "text" ? (
  // This block will handle the "text" type fields
  <div className="labelInputFull">
    <input
      type="text"
      placeholder={field.placeholder || ""}
      className="nodePanel-inputs mt-0"
      name={field.name}
      value={(data.fields || {})[field.key]}
      onChange={(e) =>
        updateData({
          ...data,
          fields: { ...data.fields, [field.key]: e.target.value },
        })
      }
    />
  </div>
): multi ? (
                <>
                  {/* <CreatableSelect
                    components={{ DropdownIndicator: null }}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={(newValue) =>
                      updateData({
                        ...data,
                        fields: { ...data.fields, [field.key]: newValue },
                      })
                    }
                    onInputChange={(newValue) => setInputValue(newValue)}

                    onKeyDown={(event) => {
                      if (!inputValue) return;
                      switch (event.key) {
                        case "Enter":
                        case "Tab":
                          updateData({
                            ...data,
                            fields: {
                              ...data.fields,
                              [field.key]: [
                                ...field.key,
                                inputValue[field.key],
                              ],
                            },
                          });
                          setInputValue("");
                          event.preventDefault();
                      }
                    }}
                    placeholder="Enter Emails"
                    value={((data.fields || {})[field.key] || []).map(
                      (mail) => {
                        return { label: mail, value: mail };
                      }
                    )}
                  /> */}

                  {/* Here comes Select */}
                  {/* <CreatableSelect
                      components={{ DropdownIndicator: null }}
                      inputValue={inputValue}
                      isClearable
                      isMulti
                      menuIsOpen={false}
                      onChange={(newValue) => setValue(newValue)}
                      onInputChange={(newValue) => setInputValue(newValue)}
                      onKeyDown={handleKeyDown}
                      placeholder="Enter Mails"
                      value={value}
                   /> */}

                </>
              ) : json ? 
                <JsonEditor mode="text" value={{}} />
             :(
              // This block will handle the "text" type fields
              <div className="labelInputFull">
                <input
                  type={field.type}
                  placeholder={field.placeholder || ""}
                  className="nodePanel-inputs mt-0"
                  name={field.name}
                  value={(data.fields || {})[field.key]}
                  onChange={(e) =>
                    updateData({
                      ...data,
                      fields: { ...data.fields, [field.key]: e.target.value },
                    })
                  }
                />
              </div>
            )}

            </>
          );
        })}
{/* 
        {data.provider && providersNamesMap[data.provider].auth ? (
          <>
            <label  className="darkerLabel">
              {providersNamesMap[data.provider].name} Specifics
            </label>
          </>
        ) : null} */}

        {/* Additional Provider Fields */}
        {((data.provider && providersNamesMap[data.provider].auth) || []).map(
          (field) => {
            return (
              < >
                <label class="nodePanel-labels">{field.name || field.key}</label>
<div className="labelInputFull">
<input
                  type={field.type || "text"}
                  placeholder={field.placeholder || ""}
                  className="nodePanel-inputs mt-0"
                  name={field.name}
                  value={(data.auth || {})[field.key]}
                  onChange={(e) =>
                    updateData({
                      ...data,
                      auth: { ...data.auth, [field.key]: e.target.value },
                    })
                  }
                />
</div>
                

              </>
            );
          }
        )}
      </div>


      </div>

      
    </div>
  );
};

export const NovuNode = ({ id, data, selected }) => {
  const novuType = {

  };

  switch (data?.type) {
    case "Email":
      novuType["style"] = { backgroundColor: "#F5ECFF", border: selected ? `solid 2px${"#8000FF"}` : "" };
      novuType["img"] = Icons.emailNodeLogo;
      break;
    case "Notification":
      novuType["style"] = { backgroundColor: "#FFF2E5", border: selected ? `solid 2px${"#FF8000"}` : "" };
      novuType["img"] = Icons.notificationNodeLogo;
      break;
    case "Chat":
      novuType["style"] = { backgroundColor: "#E1FFE6", border: selected ? `solid 2px${"#00B51F"}` : "" };
      novuType["img"] = Icons.chatNodeLogo;
      break;
    case "SMS":
      novuType["style"] = { backgroundColor: "#D4FAFF", border: selected ? `solid 2px${"#26C6DA"}` : "" };
      novuType["img"] = Icons.SMSNodeLogo;
      break;
    default:
      novuType["style"] = ""
      novuType["img"] = "";
    // code block
  }
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected)
  }, [selected])
  return (
    <div
      className={
        "App "
        // +
        // (data.isStartNode ? "start_node" : "") +
        // (!data.isStartNode && selected ? "selected_node" : "")
      }
    >
      <div
        style={novuType.style}
        className={`${"set_msgcard"} ${"commonHeader"}`}>
        <NodeHeader
          name={
            data?.type
          }
          icon={novuType.img}
          onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        ></NodeHeader>
        <div className="novuNodeStyle">
          <div>
            <p>
              POST
            </p>
            {/* <img src={data.provider ? (
              providersNamesMap[data.provider].image
            ) : (
              <BsFillChatLeftTextFill />
            )} alt=""/> */}
            <p>{data.provider ? providersNamesMap[data.provider].name : data.type}</p>
            <NodeFooter />
          </div>

        </div>

      </div>
    </div>
  );
};

