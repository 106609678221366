import { NodeHeader } from "../../Common";
import { Handle, Position } from "reactflow";
import React, { useEffect, useState } from "react";
import Icons from "../../../../../assets/imageUrl";
import SidePanelClose from "../../../../../Components/sidePanelClose/SidePanelClose";
import { useNodeData } from "../../../../store/selector";
import { randomColor } from "../../../../../assets/imageUrl";
const nodeName = "RandomNode";


export const RandomNodePanel = ({ id, type = nodeName, vars = [] }) => {
  const [data, setData] = useNodeData(id);
  return (
    <div className="right_panel_workflow">
      <SidePanelClose nodeName={nodeName} />
      <section className="sideInnerParent">

        <div>
          <label className="nodePanel-labels">{type + " Name"} </label>
          <div className="labelInputFull">
            <input
              type="text"
              className="nodePanel-inputs"
              value={data.name || ""}
              maxLength={15}
              onChange={(e) => setData({ ...data, name: e.target.value })}
              placeholder={type + " Name"}
            />
          </div>

        </div>
        <div>
          <label className="nodePanel-labels" htmlFor="">Select Type</label>
          <div className="labelInputFull">
          <select value={data?.type || ""} name="" id=""
                  onChange={(e) => {
                    setData({ ...data, type: e.target.value });
                  }}
          >
            <option value="" disabled>Select</option>
            <option value="Number">Number</option>
            <option value="String">String</option>
            <option value="GUID">GUID</option>
          </select>
          </div>
         
        </div>
        {
          data.type === "Number" ? <div>
            <label htmlFor="" className="nodePanel-labels">
              MIN Value:
            </label>
            <div className="labelInputFull">
              <input value={data?.min || ""} onChange={(e) => setData({ ...data, min: parseInt(e.target.value) })}
                     type="number" />
            </div>

            <label htmlFor="" className="nodePanel-labels">
              MAX Value:
            </label>
            <div className="labelInputFull">
              <input value={data?.max || ""} onChange={(e) => setData({ ...data, max: parseInt(e.target.value) })}
                     type="number" />

            </div>
          </div> : data.type === "String" ? <div>
            <label htmlFor="" className="nodePanel-labels">
              String Length:
            </label>
            <div className="labelInputFull">
              <input value={data?.length || ""} onChange={(e) => setData({ ...data, length: parseInt(e.target.value) })}
                     type="text" />
            </div>
            <div>
              <label htmlFor="" className="nodePanel-labels">Include</label>
              <div className="includeCheckBox">
                <div>
                  <p>Caps: </p>
                  <input checked={data?.includeCaps || false}
                         onChange={(e) => setData({ ...data, includeCaps: e.target.checked })} type="checkbox" name=""
                         id="" />
                </div>
                <div>
                  <p>Special Character: </p>
                  <input checked={data?.includeSpecialChars || false}
                         onChange={(e) => setData({ ...data, includeSpecialChars: e.target.checked })} type="checkbox" name=""
                         id="" />
                </div>
                <div>
                  <p>Numbers:</p>
                  <input checked={data?.includeNumbers || false}
                         onChange={(e) => setData({ ...data, includeNumbers: e.target.checked })} type="checkbox" name=""
                         id="" />
                </div>
              </div>

            </div>
          </div> : data.type === "guid" ? <div>
            <label htmlFor="" className="nodePanel-labels">GUID Version</label>
            <select value={data?.version || ""} onChange={(e) => setData({ ...data, version: e.target.value })} name=""
                    id="">
              <option value="" disabled>Select</option>
              <option value="V4">V4</option>
            </select>
          </div> : <></>
        }

        <div>
          <label className="nodePanel-labels" htmlFor="">Variable:</label>
          <div className="labelInputFull">
            <input value={data?.variable || ""} onChange={(e) => setData({ ...data, variable: e.target.value })}
                   type="text" />
          </div>
        </div>
      </section>
    </div>
  );
};

const RandomNode = ({ id, data, selected }) => {
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected);
  }, [selected]);
  return (
    <div
      className={
        " "
        // +
        // (data.isStartNode ? "start_node" : "") +
        // (!data.isStartNode && selected ? "selected_node" : "")
      }
    >
      <div style={{ backgroundColor: "#E1FFE6", border: selected ? `solid 2px${"#00B51F"}` : "" }}
           className={`${"set_msgcard"} ${"commonHeader"}`}>
        <NodeHeader
          name={nodeName}
          icon={randomColor}
          onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        ></NodeHeader>
        <div className="updateNodeStyle">
          <div>
            <p>POST</p>
            <Handle
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RandomNode;
