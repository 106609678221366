import { NodeHeader } from "../../Common";
import Icons from "../../../../../assets/imageUrl";
import React, { useMemo,useEffect,useState } from "react";
import { Handle, Position } from "reactflow";
import { useNodeData } from "../../../../store/selector";
import SidePanelClose from "../../../../../Components/sidePanelClose/SidePanelClose";
import { useNavigate } from "react-router";

export const PagePanel = ({ id, children, type = "Page" }) => {
  const [data, setData] = useNodeData(id);

  const updateField = e => setData({ ...data, [e.target.name]: e.target.value });

  return <div className="right_panel_workflow">
    <SidePanelClose nodeName="Page" />
    <section className="sideInnerParent">
      <div>
      <label className={"nodePanel-labels"}>
        {type + " Name"}
      </label>
      <div className="labelInputFull">
      <input
        type="text"
        name="name"
        className="mb-2 nodePanel-inputs"
        value={data.name || ""}
        onChange={updateField}
      />
      </div>
      
      </div>
      
    </section>
  </div>;
};

let navigationComponents = ["Button", "oneTimeCode", "Camera"]

const getIdOfFormsAndButtons = (page) =>{
  let ids = [];
  if (page && Array.isArray(page.components)){

    page.components.forEach(comp =>{
      if (navigationComponents.includes(comp.type)){
        let name;

        switch (comp.type){
          case "Button":
            name = (comp?.props?.text) || "Button"
            break;
          case "oneTimeCode":
            name= (comp?.props?.resendButton) || "Resend OTP"
            break;
          case "Form":
            name = (comp?.props?.name) || "Form"
            break;
          case "Camera":
            name = (comp?.props?.name) || "Camera"
            break;
          default:
            name = "UnKnown"
        }
        ids.push({ id: comp.id, type: comp.type, name: name })
        return
      }
      let result = getIdOfFormsAndButtons(comp)
      ids = [...ids, ...result];
    })
  }

  return ids
}

export default function PageNode(
  {
    id,
    name = "Page",
    selected,
    style
  }
) {
  const navigate = useNavigate();
  console.log("Page ID is ", id);

  const [data, _] = useNodeData(id);
  const openPageBuilder = () => navigate(`pages/${id}`);
  const ids = useMemo(() => getIdOfFormsAndButtons(data), [data])

  if (!data){
    return undefined
  }

  return <div className="App" onDoubleClick={openPageBuilder}>
    <div style={style ? style : { backgroundColor: "#e8f4ff", border: selected ? `solid 2px ${"#81d3ff"}` : "" }}
         className={`${"set_msgcard"} ${"commonHeader"}`}>
      <div></div>
      <NodeHeader
        name={`${data?.name}`}
        onEdit={openPageBuilder}
        noHandle={false}
        icon={Icons.desktopNodeIcon}
        accessSelected={selected}
      />

      <div className="messageNodeStyle">
        {ids.map(comp => {
          return (
            <div className="interactiveButtons">
              <button>{comp.name}</button>
              <Handle
                id={comp.id}
                type="source"
                className="commonHandleStyle"
                position={Position.Right}
              />
            </div>
          );
        })}
      </div>
    </div>
  </div>;
}