import { NodeHeader, StartNode } from "../../Common";
import { TbVariable } from "react-icons/tb";
import { Items } from "../APICall/Apicall";
import {
  Handle,
  Position,
} from "reactflow";
import React, { useState, useEffect } from "react";
import Icons from "../../../../../assets/imageUrl";
import SidePanelClose from "../../../../../Components/sidePanelClose/SidePanelClose";
import { useNodeData } from "../../../../store/selector";
import { timeDelayColor } from "../../../../../assets/imageUrl";
const nodeName = "TimeDelay";




export const TimeDelayPanel = ({ id, type = nodeName, vars = [] }) => {
    
  
  const [data, setData] = useNodeData(id);

  return (
    <div className="right_panel_workflow">
      <SidePanelClose nodeName={nodeName } />
<section className="sideInnerParent">

      <div >
        <label className="nodePanel-labels">{type + " Name"} </label>
<div className="labelInputFull">
<input
          type="text"
          className="nodePanel-inputs"
          value={data.name || ""}
          maxLength={15}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          placeholder={type + "Node" + " Name"}
        />
</div>
       
      </div>
      <div>
        <label className="nodePanel-labels" htmlFor="">Seconds*</label>
        <div className="labelInputFull">
        <input value={data?.delseconds || ""} onChange={(e)=> setData({...data,delseconds:e.target.value})} type="text" />
        </div>

      </div>
      <div>
        <label className="nodePanel-labels" htmlFor="">Variable*</label>
        <div className="labelInputFull">
        <input value={data?.variable || ""} onChange={(e)=> setData({...data,variable:e.target.value})} type="text" />
        </div>

      </div>
      </section>
    </div>
  );
};

const TimeDelayNode = ({ id, data, selected }) => {
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected)
  }, [selected])
  return (
    <div
      className={
        " "
        // +
        // (data.isStartNode ? "start_node" : "") +
        // (!data.isStartNode && selected ? "selected_node" : "")
      }
    >
      <div style={{ backgroundColor: "#E1FFE6", border: selected ? `solid 2px${"#00B51F"}` : "" }} className={`${"set_msgcard"} ${"commonHeader"}`}>
        <NodeHeader
          name={nodeName}
          icon={timeDelayColor}
          // onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        ></NodeHeader>
        <div className="updateNodeStyle">
          <div>
            <p>POST</p>
            <Handle
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeDelayNode;
