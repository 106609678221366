import { FiPlusCircle } from "react-icons/fi";
import { Handle, Position } from "reactflow";
import { deleteModelImg, penModelImg } from "../../../assets/imageUrl";
import React, { useEffect, useRef, useState } from "react";
import { useRemoveNode } from "../../store/selector";
import {accessMenuToggle} from '../../../assets/imageUrl'
// import {validationSchema} from '../../workflowValidation'
export function NodeHeader({
                             name,
                             onEdit,
                             icon,
                             children,
                             noHandle = false,
                             accessSelected
                           }) {
  const [accessMenu, setAccessMenu] = useState(false);
  const removeNode = useRemoveNode();

  useEffect(() => {
    if (accessSelected === false) {
      setAccessMenu(false);
    }
  }, [accessSelected]);
  

  return (
    <>
      {!noHandle && (
        <Handle
          type="target"
          position={Position.Left}
          onConnect={(params) => console.log('handle onConnect', params)}
          className="startHandle"
        ></Handle>
      )}
      <div >
        <div className="commonHeaderNode">
          
           <div>
              <img src={icon} alt="Img" />
            <h3>{name}</h3>
           </div>
          

          <div
            onClick={() => {
              setAccessMenu(!accessMenu);
            }}
            className="selectMenu"
          >
            <img src={accessMenuToggle} alt="toggleImg" />
          </div>
          {accessMenu && (
            <div className="accessMenu">
              <div onClick={onEdit}>
              
                  <img src={penModelImg} alt="edit" />
                

                <p>Edit</p>
              </div>
              <div
                onClick={removeNode}
              >
                <img src={deleteModelImg} alt="delete" />
                <p>Delete</p>
              </div>
            </div>
          )}
        </div>

        {children}
      </div>
    </>
  );
}

export function NodeFooter({ name, onClick, onDelete, children }) {
  
  return (
    <>
      <Handle
        type="source"
        position={Position.Right}
        className="commonHandleStyle"
        
      ></Handle>
    </>
  );
}

export const Plus = ({ onClick }) => {
  return (
    <>
      <div className="plus">
        <FiPlusCircle onClick={onClick} />
      </div>
    </>
  );
};

export const StartNode = ({
                            isStartNode,
                            setIsStartNode,
                            text = "Start Node?"
                          }) => {
  // return <></>
  return (
    <div className="PB-rightside-panel toggle mb-2">
    <label class="switch">
        <input type="checkbox"
            checked={isStartNode}
            onChange={() => setIsStartNode(!isStartNode)}
            ></input>
        <span style={{backgroundColor:"#7CC791"}}  class="slider"></span>
    </label>
    <label htmlFor="name" className="PB-side-input-label m-2 mr-4">{text}</label>
</div>
      
  );
};

export const getMeasurements = (id, store) => {
  const state = store.getState();
  let width, height;
  Array.from(state.nodeInternals.values()).forEach((node) => {
    if (node.id === id) {
      width = node.width;
      height = node.height;
    }
  });
  return [width, height];
};

export const FallBack = ({ data, update }) => {
  return (
    <>
      
      <div>
        <label htmlFor="url" className="nodePanel-labels">
          Fall back
        </label>
        <div className="labelInputFull">
        <input
          type="text"
          name=""
          id="url"
          value={data}

          onChange={(e) => update(e.target.value)}
        />
      </div>
      </div>
      
    </>
  );
};

export const VariablePick = ({ placeholder,name, input, setInput, vars=[]  }) => {
 
  const [inputValue, setInputValue] = [input,setInput];
  const [showVariable,setShowVariable] = useState(false);
  const [variableSpan,setVariableSpan] = useState(true);
  const inputRef = useRef(null);
  

  const DesignVariable=()=>{
    const matches = [];
    const regex = /{{(.*?)}}|([^{}]+)/g;
    let match;
    let lastIndex = 0;
  while ((match = regex.exec(inputValue)) !== null) {
    if (match.index > lastIndex) {
      matches.push({ covered: false, string: inputValue.substring(lastIndex, match.index) });
  }

  if (match[1]) {
      matches.push({ covered: true, string: match[1] });
  }

  lastIndex = match.index + match[0].length;
  }
  if (lastIndex < inputValue.length) {
    matches.push({ covered: false, string: inputValue.substring(lastIndex) });
}

 return( <>
    {matches.map((match,index)=>{
      return <>
      {match.covered ? (
        <span style={{ color: 'blue' }}>
            {match.string}
        </span>
    ) : (
      <span>
        {match.string}
      </span>
        
    )}
      </>
       
    })}

  
</>)
  }

  
  
  

  const handleCursorInput=()=>{
    if (inputRef.current) {
      const cursorPos = inputRef.current.selectionStart || 0;
      if((inputValue?.[cursorPos-1]==="{")){
        setShowVariable(true);
      }else{
        setShowVariable(false);
      }
    }
  }

useEffect(()=>{
  handleCursorInput();
},[inputValue])
  const handleBraceRemoval = (insertEle) => {
    let genValue=inputValue.split("");
    if (inputRef.current) {
      const cursorPos = inputRef.current.selectionStart || 0;

      let leftOpenBraceIndex = cursorPos - 1;

      let count = 0
      
      for(let i=leftOpenBraceIndex;i>=0;i--){
       
        if(genValue[i]==="{"){
          genValue.splice(i,1);
          count++;
        }else{break;}
        
      }
      
      let rightCloseBraceIndex = cursorPos-count;
 
 
      for(let i=rightCloseBraceIndex;i<genValue.length;i++){
        if(genValue[i]==="}"){
          genValue.splice(i,1);
          i--;
      
        }else{
          break;
        }
        
      }

const elementToAdd = `{{${insertEle}}}`;
const elementToInsertAfter = cursorPos-count;
genValue.splice(elementToInsertAfter, 0, elementToAdd);


      const newValue = genValue.join(""); 
    

      setInputValue(newValue);
    }

  };    
  
  return (
    
        <>
        {
            variableSpan === false && inputValue.length>=1  ? <div className="backgroundVariable" onClick={()=>{
              setVariableSpan(true);
            }}>
              <DesignVariable></DesignVariable>

              
            </div> : 
            <div className="labelInputFull mt-2">

            <input
            type="text"
            placeholder={placeholder}
            ref={inputRef}
            value={inputValue}
            onChange={(e)=>{setInputValue(e.target.value)}}
            onKeyUp={handleCursorInput}
            onBlur={()=>setVariableSpan(false)}
          />
{(vars && vars.length>0) &&(showVariable===true) ? <ul className="variableShowStyle">
          
          {vars.map((variable) => (
            <list onClick={(e)=>{
              handleBraceRemoval(variable)
            }} value={variable}>{variable}</list>
          ))}
        
      </ul> : <>
     
      </> }
            </div>
             



          }
        </>
          
          
           
          
        
    
  );
};
