import { React, useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Icons from "../assets/imageUrl";
import Success_Img from "../assets/success_create.svg";
import DefaultNodeItems, { Node, commonNodes, communicationNodes, defaultNodeItems } from "./DefaultNodeItems";
import { useActiveSelectors } from "./store/activeStore";
import useFlow from "./store/flowStore";
import { useCustomNodes } from "./store/selector";

export default function SidePanel({ }) {
  const [saving, setSaving] = useState();
  const [customNodes, _] = useCustomNodes();
  const [openNodesPanel, setOpenNodesPanel] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Frequently used");
  const flowType = useFlow(flow => flow.type);
  const isPage = flowType === "web";

  const uniqueNodes = useMemo(() => defaultNodeItems(flowType), [flowType]);

  const sortedCustomNodes = useMemo(() => {
    const sortedNodes = {};

    customNodes.forEach((item) => {
      const { category, sub_category } = item;

      if (!sortedNodes[category]) {
        sortedNodes[category] = {};
      }

      if (!sortedNodes[category][sub_category]) {
        sortedNodes[category][sub_category] = [];
      }

      sortedNodes[category][sub_category].push(item);
    });
    return sortedNodes
  }, [customNodes])


  let navigate = useNavigate();


  const handleOpenNodePanel = (value = !openNodesPanel) => {
    setOpenNodesPanel(value);
    setShowCategories(false);
  };

  const onSaveButton = async () => {
    setSaving(true);
    try {
      await onSave(alert);
    } catch (e) {
      console.log(e);
    }
    setSaving(false);
    // setMessage("Saved...!")
  };

  const handleKeyDown = async (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      await onSaveButton();
    }

  };

  useEffect(() => {
    const handleDocumentOnClick = () => {
      handleOpenNodePanel(false)
      setShowCategories(false);
    }
    document.addEventListener('keydown', handleKeyDown)

    document.addEventListener('click', handleDocumentOnClick);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleDocumentOnClick)
    }
  })


  const toolTipStyle = {
    borderRadius: "4px",
    height:"30px",
    fontSize: "10px",
    color:"#fff",
    background:"#222222"
  };

  const stopPropagation = event => {
    event.stopPropagation();
    event.preventDefault();
  }

  let nodes;

  switch (selectedCategory) {
    case "Frequently used":
      nodes = <div>
        <p className="side-panel-frequently">Frequently Used</p>
        <div className="container">
          <div className="row">
            {[...uniqueNodes, ...commonNodes].map(node => <Node node={node} className="side_nodes" />)}
          </div>
        </div>
      </div>;
      break;
    case "Communication Nodes":
      nodes = <div>
        <p className="side-panel-frequently">Communication Nodes</p>
        <div className="container">
          <div className="row">
            {communicationNodes.map(node => <Node node={node} className="side_nodes" />)}
          </div>
        </div>
      </div>
      break;
    default:
      nodes = <div>
        <p className="side-panel-frequently">{selectedCategory}</p>
        <div className="container">
          {Object.keys(sortedCustomNodes[selectedCategory]).map(subCategory => (
            <div className="row" key={subCategory}>
              {sortedCustomNodes[selectedCategory][subCategory].map(node => <Node key={node.id} node={{
                name: node?.name,
                logo: node?.logo,
                type: "ExecuteAPI",
                id: node?.id
              }} className="side_nodes" />)}
            </div>
          ))}
        </div>
      </div>

  }


  return <div onClick={stopPropagation}>
    {openNodesPanel &&
      <div className="wrapper_rajer" >
        <section className="left-side-panel">
          <div className="searchBar">
            <img src={Icons?.SearchIcon} alt="search" className="icon" />
            <input
              type="search"
              placeholder="Search"
              className="input"
            />
            <img src={Icons?.downArrow} alt="arrow" className={showCategories ? "arrowDown" : "arrowUp"} onClick={() => setShowCategories(!showCategories)} />
            <div>
            </div>
          </div>
          {nodes}

        </section>
      </div>
    }
    <div className="Icons_sakku" >
      <div className="sakku tool-chats" onClick={() => handleOpenNodePanel()}>
        <img src={Icons?.flowIcon1} />
        <Tooltip anchorSelect=".tool-chats" place="right" style={toolTipStyle}>
          Nodes
        </Tooltip>
      </div>

      {isPage && <div className={"sakku tool-pagebuilder"} onClick={() => navigate("pages")}>
        <img src={Icons?.flowIcon2} />
        <Tooltip anchorSelect=".tool-pagebuilder" place="right" style={toolTipStyle}>
          Page Builder
        </Tooltip>
      </div>}
      {isPage && <div className={"sakku tool-brands"} onClick={() => navigate("settings")}>
        <img src={Icons?.flowIcon3} />
        <Tooltip anchorSelect=".tool-brands" place="right" style={toolTipStyle}>
          Brand Settings
        </Tooltip>
      </div>}

    </div>
    {showCategories && openNodesPanel &&
      <>
        <div className="nodes-list-main">
          <ul className="node-list">
            {["Frequently used", "Communication Nodes", ...Object.keys(sortedCustomNodes)]
              .map(key => <li className="node-list-1"
                onClick={() => {
                  setSelectedCategory(key);
                  setShowCategories(false)
                }}
                value={key}>{key}</li>)}
          </ul>
        </div>
      </>
    }
  </div>;
}


