import React from 'react'
import { GrClose } from 'react-icons/gr'

const SidePanelClose = ({ nodeName }) => {
    return (
        <div className="rightSidePanelHeader">
          <div>
          <p >{nodeName} node properties</p>
          </div>
                       <div>
            <GrClose className="cpoint" />
            </div>
        </div>
    )
}

export default SidePanelClose