const getInputs = (obj) => {
    let result = [];
    if (Array.isArray(obj)) {
        for (const val of obj) {
            result = result.concat(getInputs(val));
        }
    } else if (typeof obj === "object") {
        for (const key in obj) {
            if (key.startsWith("$")) {
                result.push(key.slice(1));
            } else {
                result = result.concat(getInputs(obj[key]));
            }
            if (typeof obj[key] === "string") {
                const matches = obj[key].match(/\{\{(\w+)\}\}/g);
                if (matches) {
                    for (const match of matches) {
                        result.push(match.slice(2, -2));
                    }
                }
                if (obj[key].startsWith("$")) {
                    result.push(obj[key].slice(1));
                }
            }
        }
    }
    return result;
};

const removeB = item => {
    const matches = item.match(/\{\{(\w+)\}\}/g);
    if (matches) {
        for (const match of matches) {
            return match.slice(2, -2) // TODO handle array of matches
        }
    }
    if (item.startsWith("$")) {
        return item.slice(1)
    }
}

const getAllStringValues = obj => {

    let result = [];
    if (Array.isArray(obj)) {
        for (const val of obj) {
            result = result.concat(getAllStringValues(val));
        }
    } else if (typeof obj === "object") {
        for (const key in obj) {
            result = result.concat(getAllStringValues(obj[key]));
            // if (typeof obj[key] === "string") {
            //     result.push(obj[key]);
            // }
        }
    } else if (typeof obj === "string") {
        result.push(obj);
    }
    return result;
}

const areAllValuesString = obj => {
    if (Array.isArray(obj)) {
        return obj.every((val) => areAllValuesString(val));
    } else if (typeof obj === "object") {
        for (const key in obj) {
            if (!areAllValuesString(obj[key])) {
                return false;
            }
        }
        return true;
    } else {
        return typeof obj === "string";
    }
}


// const flow = {
//     "_id": "lrilhvELn47XDaWzr0Jrc",
//     "name": "Name",
//     "organization": 1,
//     "extra": {},
//     "nodes": [
//         {
//             "id": "XDaWzr0Jrc",
//             "type": "Webhook",
//             "data": {
//                 "isStartNode": true,
//                 "name": "Do Pan Check",
//                 "fields": {
//                     "pan": "panNumber",
//                     "phoneNumber": "mobileNumber"
//                 }
//             }
//         },
//         {
//             "id": "firstNode",
//             "type": "ExecuteAPI",
//             "data": {
//                 "isStartNode": true,
//                 "name": "firstNode",
//                 "nodeId": "BCQXDaWzr0Jrc_lrilhvELn47jI",
//                 "inputs": [
//                     {
//                         "id": "firstOutput",
//                         "name": "joke",
//                         "value": "fact"
//                     }
//                 ],
//                 "outputs": [
//                     {
//                         "id": "firstOutput",
//                         "name": "joke",
//                         "value": "fact"
//                     }
//                 ]
//             }
//         },
//         {
//             "id": "LK2UP0xoaH",
//             "type": "UpdateVariable",
//             "position": {
//                 "x": 480,
//                 "y": 435
//             },
//             "positionAbsolute": {
//                 "x": 480,
//                 "y": 435
//             },
//             "width": 184,
//             "height": 40,
//             "selected": false,
//             "dragging": false,
//             "data": {
//                 "isStartNode": false,
//                 "name": "updatePhoneNumb",
//                 "variables": [
//                     {
//                         "id": "6bT3heqcY5",
//                         "name": "dummy",
//                         "value": "A Dummy Value"
//                     }
//                 ]
//             }
//         },
//
//         {
//             "id": "oj2D9voqUm",
//             "type": "APICall",
//             "position": {
//                 "x": 615,
//                 "y": 270
//             },
//             "positionAbsolute": {
//                 "x": 615,
//                 "y": 270
//             },
//             "width": 208,
//             "height": 74,
//             "selected": true,
//             "dragging": false,
//             "data": {
//                 "isStartNode": false,
//                 "name": null,
//                 "url": "https://ifsc.razorpay.com/{{IFSC_Code}}",
//                 "method": "GET",
//                 "body": null,
//                 "headers": null,
//                 "params": null,
//                 "cookies": null,
//                 "status_codes": [
//                     {
//                         "id": "x3nfhtbHRH",
//                         "status": 200,
//                         "variables": {
//                             "BRANCH": "Branch",
//                             "ADDRESS": "Address",
//                             "STATE": "State",
//                             "CONTACT": "Contact",
//                             "CITY": "City",
//                             "DISTRICT": "District",
//                             "BANK": "Bank",
//                             "IFSC": "IFSC"
//                         }
//
//                     },
//                     {
//                         "id": "grtx9t7rgD",
//                         "status": 404,
//                         "variables": {
//                             "msg": "failedMessage",
//                             "respcode": "failedRespCode"
//                         }
//                     }
//                 ]
//             }
//         },
//
//         {
//             "id": "i3hvmsCFo1",
//             "type": "Trigger",
//             "position": {
//                 "x": 495,
//                 "y": 405
//             },
//             "positionAbsolute": {
//                 "x": 495,
//                 "y": 405
//             },
//             "width": 205,
//             "height": 40,
//             "selected": false,
//             "dragging": false,
//             "data": {
//                 "isStartNode": false,
//                 "name": "new attact to drive",
//                 "id": "DtNtkL4AMupGydcg9l-NsD1vXus",
//                 "variables": [
//                     {
//                         "id": "j6mnkyXIOc",
//                         "name": "Name",
//                         "value": "Documents Update"
//                     }
//                 ]
//             }
//         },
//         {
//             "id": "SEPkYGvB0a",
//             "type": "Novu",
//             "position": {
//                 "x": 150,
//                 "y": 135
//             },
//             "positionAbsolute": {
//                 "x": 150,
//                 "y": 135
//             },
//             "width": 208,
//             "height": 44,
//             "selected": false,
//             "dragging": false,
//             "data": {
//                 "isStartNode": true,
//                 "name": "First Message",
//                 "provider": "ms-teams",
//                 "auth": {},
//                 "fields": {
//                     "webhookUrl": "https://m2psolutions.webhook.office.com/webhookb2/8c9411dd-5d9a-4628-b22e-9af355f052ad@3405f089-be04-4322-8340-6550f2deee0a/IncomingWebhook/5a30d72b1d1a4c35bab0afbdff2fff44/82b61e75-5f6e-43d7-9d9d-bb2230ab530f",
//                     "content": "Message is {{message}}"
//                 },
//                 "type": "chat"
//             }
//         },
//
//
//
//         {
//             "id": "upslBv6ip6",
//             "type": "Condition",
//             "position": {
//                 "x": 1995,
//                 "y": -240
//             },
//             "positionAbsolute": {
//                 "x": 1995,
//                 "y": -240
//             },
//             "width": 184,
//             "height": 40,
//             "selected": false,
//             "dragging": false,
//             "data": {
//                 "isStartNode": false,
//                 "name": null,
//                 "conditions": [
//                     {
//                         "id": "GEReOQZnHs",
//                         "condition": "Equal",
//                         "variable": "fdse",
//                         "value": "{{sdfe}}"
//                     },
//                     {
//                         "id": "GEReOQZnHs",
//                         "condition": "Equal",
//                         "variable": "$fds",
//                         "value": "{{sdf}}"
//                     }
//                 ],
//                 "operator": null
//             }
//         }
//
//     ],
//     "edges": [
//         {
//             "id": "edge_one",
//             "source": "XDaWzr0Jrc",
//             "target": "firstNode"
//         },
//         {
//             "id": "edge_two",
//             "source": "firstNode",
//             "sourceHandle": "yq5Wj9SnPc",
//             "target": "LK2UP0xoaH"
//         },
//         {
//             "id": "edge_three",
//             "source": "LK2UP0xoaH",
//             "target": "oj2D9voqUm"
//         },
//         {
//             "id": "edge_four",
//             "source": "oj2D9voqUm",
//             "sourceHandle": "x3nfhtbHRH",
//             "target": "i3hvmsCFo1"
//         },
//         {
//             "id": "edge_five",
//             "source": "oj2D9voqUm",
//             "sourceHandle": "grtx9t7rgD",
//             "target": "SEPkYGvB0a"
//         },
//         {
//             "id": "edge_five",
//             "source": "SEPkYGvB0a",
//             "target": "upslBv6ip6"
//         },
//
//
//     ]
//
// }

const getVars = (node) => {

    let inputs = [];
    let outputs = [];
    switch (node.type) {

        case "Webhook": {
            // No Inputs for Webhook
            outputs = Object.values(node?.data?.fields || {})
            break;
        }

        case "ExecuteAPI": {
            console.log(node);
            const outputs = {};

            const outs = node?.data?.outputs || [];
            console.log(outs);

            outs.forEach(output => {
                outputs[output.id] = [...new Set(getAllStringValues((output?.outputs || []).map(o => o.value)))];
            });

            const inputs = (node?.data?.inputs || []).flatMap(input => getAllStringValues(input.value));

            return [Array.from(new Set(inputs)), outputs];


        }


        case "UpdateVariable": {
            let variables = node?.data?.variables || [];
            let references = getInputs(variables);
            let outputs_ref = variables.map(cond => cond.name).filter(variable => !references.includes(removeB(variable)))
            inputs = references;
            outputs = outputs_ref
            break;
        }

        case "APICall": {
            inputs = getInputs(node?.data);
            let outputs = {};
            (node?.data?.status_codes || []).forEach(element => {
                outputs[element.id] = Array.from(new Set(getAllStringValues(element.variables)).values())
            });

            return [Array.from(new Set(inputs).values()), outputs]
        }

        case "Condition":
        case "Zapier":
        case "Novu":
            inputs = getInputs(node?.data)
            break;

        default:

    }

    return [Array.from(new Set(inputs).values()), Array.from(new Set(outputs).values())]


}


export const getVariables = (flow) => {

    let varsMap = {};

    const doWork = (node, vars = []) => {

        let [, outputs] = getVars(node)

        if (typeof outputs === 'object' && outputs !== null && !Array.isArray(outputs)) {
            for (let handle in outputs) {
                let edges = flow.edges.filter(edge => edge.source === node.id && edge.sourceHandle === handle)
                edges.forEach(edge => {
                    flow.nodes.filter(node => node.id === edge.target).forEach(node => {
                        let varsNew = Array.from(new Set(vars.concat(outputs[handle])))
                        doWork(node, varsNew)
                    })
                })
            }

            varsMap[node.id] = vars
            return
        }

        let varsNew = Array.from(new Set(vars.concat(outputs)));

        let edges = flow.edges.filter(edge => edge.source === node.id);

        for (let edge of edges) {
            let targetNode = flow.nodes.filter(node => node.id === edge.target);
            if (targetNode.length > 0) {
                doWork(targetNode[0], varsNew)
            }


        }

        varsMap[node.id] = vars
    }

    let webhook_nodes = flow.nodes.filter(node => node.type === "Webhook")

    webhook_nodes.forEach(node => {

        // varsMap[node.id] = getVars(node)
        doWork(node)
    }
    )
    console.log(varsMap)
    return varsMap
}