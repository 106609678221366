import { addEdge, applyEdgeChanges, applyNodeChanges, updateEdge } from "reactflow";
import { create } from "zustand";
import { generateId } from "../../utils/store";
import { defaultSettings, defaultWorkFlow } from "./default";
import {defaultPage} from "components";

export const useNodes = create((set, get) => ({
  nodes: [],
  setNodes: nodes => set({nodes})
}))



const useFlow = create((set, get) => ({
  type: "web",
  edgeUpdateSuccessful: true,
  ...defaultWorkFlow(),
  getNodes: () => get().nodes,
  setName: name => set({ name }),
  getFlow: () => get(),
  setNodes: nodesSetter => set({ nodes: nodesSetter(get().nodes) }), //set({nodes: nodes}),
  setEdges: edgeSetter => set({ edges: edgeSetter(get().edges) }),//set({edges: edges}),
  setViewPort: viewport => set({viewport}),
  setToDefault: type => set({ ...defaultWorkFlow(type), type: type || "web" }),
  setFromWorkflow: workflow => set({ ...workflow }),
  onNodesChange: (changes) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes)
    });
  },

  onEdgesChange: (changes) => {
    set({
      edges: applyEdgeChanges(changes, get().edges)
    });
  },

  onConnect: (connection) => {
    set({
      edges: addEdge(connection, get().edges)
    });
  },
  removeNode: nodeId => (set({ nodes: get().nodes.filter(node => node.id !== nodeId) })),

  onDrop: (event, reactFlowWrapper, rfInstance) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("type");
    const subType = event.dataTransfer.getData("subType");
    let nodeId = event.dataTransfer.getData("nodeId");

    if (typeof type === "undefined" || !type) {
      return;
    }

    const position = rfInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top
    });

    const id = generateId();

    get().setNodes((nodes) => {
      let data = {};

      if (nodeId) {
        data.nodeId = nodeId;
      }

      if (subType) {
        data.type = subType;
      }

      if (type === "Page") {
        // If Page Add default Content
        data = {
          ...data,
          ...defaultPage()
        };
      }


      return nodes.concat({
        id: id,
        type: type,
        data: data,
        position: position
      });
    });
  },

  createNewPageNode: data =>{
    get().setNodes(nodes => nodes.concat({
      id: generateId(),
      type: "Page",
      position: {
        x: 250,
        y: 250
      },
      selected: false,
      data,
    }))
  },

  getPageNodes: () => get().nodes.filter(node => node.type === "Page"),

  onEdgeUpdateStart: () => (set({edgeUpdateSuccessful: false})),

  onEdgeUpdate: (oldEdge, newConnection) => {
  set({
    edgeUpdateSuccessful: true,
    edges: updateEdge(oldEdge, newConnection, get().edges)
  })
  },

  onEdgeUpdateEnd: (_, edge) => {
    if (!get().edgeUpdateSuccessful){
      set({
        edges: get().edges.filter(e => e.id !== edge.id)
      })
    }
  }

}));


export const useSettings = create((set, get) => ({
  ...defaultSettings,
  getSettings: () => get(),
  setBrandSettings: brand => (set(
    {
      brand

    }
  )),
  setPortalSettings: portal => (set(
    {
      portal
      }
  )),
  setSettings: settings =>{
    set({...settings})
  }
}))

export const selector = state => ({
  nodes: state.nodes,
  type : state.type,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  onDrop: state.onDrop,
  onEdgeUpdate: state.onEdgeUpdate,
  onEdgeUpdateStart: state.onEdgeUpdateStart,
  onEdgeUpdateEnd: state.onEdgeUpdateEnd,
});

export default useFlow;