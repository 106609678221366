import React, { useEffect, useState } from "react";
import "./apitest.css";
import { JsonEditor } from "jsoneditor-react";
import useFlow from "../../../WorkFlowBuilder/store/flowStore";
// import { useFlowId } from "../NavBar";
import { useFlowId } from "../../NavBar";
import axios from "axios";
import { commonRequestV1 } from "../../../api/common";
import { generateId } from "components/src/stores/util";
import SpinningLoader from "./loader";

const useWebhookNode = () => {
  const nodes = useFlow((flow) => flow.nodes);
  return nodes.filter((node) => node.type === "Webhook");
};
const ApiTest = () => {
  const nodes = useWebhookNode();
  const [selectedNode, setSelectedNode] = useState(nodes[0].id);
  const [selctedMethod, setSelectedMethod] = useState("POST");
  const flowId = useFlowId();
  const [response, setResponse] = useState({});
  const [body, setBody] = useState(
    nodes.find((node) => node.id === selectedNode)?.data?.fields
  );
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setBody(nodes.find((node) => node.id === selectedNode)?.data?.fields);
  }, [selectedNode]);

  const sendRequest = async () => {
    try {
      setLoader(true);
      let response = await commonRequestV1(
        selctedMethod,
        `/workflows/${flowId}/${selectedNode}`,
        body
      );
      setResponse(response);
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };
  return (
    <>
      <div className="container ">
        <div className="apitest-main">
          <div className="apitest-head">
            <h4>Demo API / New Request</h4>
            <select
              onChange={(e) => setSelectedNode(e.target.value)}
              style={{ width: "250px" }}
            >
              {nodes?.map((node) => (
                <option value={node?.id} selected={node.id === selectedNode}>
                  {node?.data?.name}
                </option>
              ))}
            </select>
          </div>
          <div className=" row apitest-main-div">
            <div className="col-lg-2">
              <select onChange={(e) => setSelectedMethod(e.target.value)}>
                <option value="POST" selected={selctedMethod === "POST"}>
                  POST
                </option>
                <option value="PUT" selected={selctedMethod === "PUT"}>
                  PUT
                </option>
              </select>
            </div>
            <div className="col-lg-8 api-test-inputurl">
              <input
                type="text/number"
                value={`https://stg-api.idflow.com/v1/workflows/${flowId}/${selectedNode}`}
              />
            </div>
            <div className="col-lg-2">
              <button
                onClick={sendRequest}
                className={`${loader ? "spinnerLoad" : ""}`}
              >
                Send {loader ? <SpinningLoader /> : ""}
              </button>
            </div>
          </div>
          <div className="api-request row">
            <h4>Request</h4>
            <JsonEditor
              mode="text"
              value={body}
              key={selectedNode}
              onChange={(value) => setBody(value)}
            />
          </div>
          <div className="api-request row">
            <h4 className="pt-4 mt-4">Response</h4>
            <JsonEditor mode="text" value={response} key={generateId()} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiTest;
