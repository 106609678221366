import { useNodeId, useNodesState } from "reactflow";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


export const useId = () =>{
  return useNodeId();
}

export const useUpdateNode = (nodeId) =>{
  const thisNodeId = useNodeId();

  const [nodes, setNodes] = useNodesState();
console.log(nodes)
  return (data) => setNodes((nodes) => nodes.map((node) => {
    if (node.id === nodeId || thisNodeId){
      node.data = data;
    }
    return node;
  }))
}


export const useFullScreen = (openOnUnmount = true) =>{
  let sendFullScreenMessage = status => {
    let event = new CustomEvent("fullScreen", {detail: {fullScreen: status}})
    window.dispatchEvent(event)
  }

  useEffect(() =>{
    sendFullScreenMessage(true)
    return () => openOnUnmount ? sendFullScreenMessage(false): undefined
  }, [])
}

export const getQueryString = name => {
  const location = useLocation();
  const url = new URLSearchParams(location.search);
  return url.get(name)
}