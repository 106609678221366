import { ConditionPanel } from "./Flow/Nodes/Common/Condition/Condition";
import { APICallPanel } from "./Flow/Nodes/Common/APICall/Apicall";
import { UpdateVariablePanel } from "./Flow/Nodes/Common/UpdateVariable/UpdateVariable";
import { Zapier } from "./Flow/Nodes/Common/zapier/zapiertrigger";
import { ExecuteAPIPanel } from "./Flow/Nodes/Common/ExecuteAPI/ExecuteAPIPanel";
import { NovuPanel } from "./Flow/Nodes/Common/Novu/NovuPanel";
import { redirect } from "react-router-dom";
import { WebhookPanel } from "./Flow/Nodes/API/Webhook/webhook";
import { getVariables } from "../utils/variables";
import Resource from "../api/resource";
import { ConversionPanel } from "./Flow/Nodes/Common/Conversion/Conversion";
import { MatrixPanel } from "./Flow/Nodes/Common/Matrix/Matrix";
import { MessagePanel } from "./Flow/Nodes/whatsapp/Message/Message";
import { QuestionPanel } from "./Flow/Nodes/whatsapp/Question/Question";
import { InteractiveListPanel } from "./Flow/Nodes/whatsapp/InteractiveList/InteractiveList";
import { InteractiveButtonsPanel } from "./Flow/Nodes/whatsapp/InteractiveButtons/InteractiveButtons";
import { useEdges, useNodes } from "reactflow";
import { useSelectedNode } from "./store/selector";
import useFlow from "./store/flowStore";
import { PagePanel } from "./Flow/Nodes/Pages/Page/page";
import {AwsS3Panel} from "./Flow/Nodes/Aws S3/AwsS3";
import { RandomNodePanel } from "./Flow/Nodes/Common/RandomNode/RandomNode";
import { TimeDelayPanel } from "./Flow/Nodes/Common/TimeDelay/TimeDelay";
import { SwitchNodePanel } from "./Flow/Nodes/Common/SwitchNode/SwitchNode";
const PanelsMap = {
  Condition: ConditionPanel, // Add Panel here to register with the React Flow
  APICall: APICallPanel,
  Webhook: WebhookPanel,
  UpdateVariable: UpdateVariablePanel,
  Trigger: Zapier,
  Novu: NovuPanel,
  ExecuteAPI: ExecuteAPIPanel,
  Conversion: ConversionPanel,
  Matrix: MatrixPanel,
  Message: MessagePanel,
  Question: QuestionPanel,
  InteractiveList: InteractiveListPanel,
  InteractiveButtons: InteractiveButtonsPanel,
  Page: PagePanel,
  AwsS3:AwsS3Panel,
  Random:RandomNodePanel,
  TimeDelay:TimeDelayPanel,
  SwitchNode:SwitchNodePanel
}


export function NodePanel() {
  const nodes = useNodes();
  const edges = useEdges();
  const selectedNode = useSelectedNode();
  const flowId = useFlow(flow => flow.id);
  let flow = { nodes: nodes, edges: edges };
  const variables = getVariables(flow);

  if (selectedNode){
    let type = selectedNode.type;
    let vars = variables[selectedNode.id]
    let Panel = PanelsMap[type] ;
    return Panel ? <Panel data={selectedNode.data} vars={vars} id={selectedNode.id} {...selectedNode} flowId={flowId} />:<></>;

  }
  return <></>
}

export const nodesLoader = async () => {
  try {

    // let nodes = await commonRequest(METHODS.get, "/nodes/all");
    return { nodes: [] };
  } catch (e) {
    if (e.response.status === 401) {
      return redirect("/login");
    }
  }
};

export const workflowLoader = async ({ request, params }) => {
  try {
    // let id = params.workflowId;
    //
    // let nodes = await commonRequest(METHODS.get, "/nodes/all");
    //
    // let flow = await Resource("workflows").get(id);

    return { nodes : [], flow: {} }
  } catch (e) {
    if (e.response.status === 401) {
      return redirect("/login");
    }
  }
}

export const workFlowDeleteAction = async ({ params }) => {
  try {
    const flowId = params.flowId;

    await Resource("workflows").delete(flowId);

    return redirect("/home/workflows");
  } catch (e) {
    if (e.response.status === 401) {
      return redirect("/login");
    }
  }
};
