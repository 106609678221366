import React, { useEffect, useMemo, useState } from "react";
import {
  FallBack,
  getMeasurements,
  NodeHeader,
  StartNode,
} from "../../Common";
import { generateId } from "../../../../../utils/store";
import {
  Handle,
  Position,
  useStoreApi,
  useUpdateNodeInternals,
} from "reactflow";
// import { MdDeleteForever } from "react-icons/md";
import { BsPlusLg } from "react-icons/bs";
import { FiMinus } from "react-icons/fi";
import Icons from "../../../../../assets/imageUrl";
import SidePanelClose from "../../../../../Components/sidePanelClose/SidePanelClose";
import { useNodeData } from "../../../../store/selector";
import { AddButton,DeleteButton } from "../../../../CommonButtons";
const Rows = ({ rows, hidePlusButton = false, updateRows }) => {
  const rowsElements = rows.map((row,i) => {
    function deleteRowsSection(row){
      updateRows(rows.filter((r) => r.id !== row.id));
    }
    return (
      <li key={row.id}>          
              <div className="commonDeleteSection">
       <label >
          Row {i+1}
         </label>
      <DeleteButton callFunction={deleteRowsSection} comId={row}/>
       </div>
       <div className="labelInputFull mt-3">
       <input
                type="text"
                value={row.title}
                placeholder="title"
                maxLength={24}
                onChange={(e) => {
                  const updatedRows = rows.map((r) => {
                    if (r.id === row.id) {
                      return {
                        ...r,
                        title: e.target.value,
                      };
                    }
                    return r;
                  });
                  updateRows(updatedRows);
                }}
              />
          
       </div>
              <div className="labelInputFull mt-3">
              <input
              type="text"
              value={row.description}
              maxLength={72}
              placeholder="Enter discription"
              onChange={(e) => {
                const updatedRows = rows.map((r) => {
                  if (r.id === row.id) {
                    return {
                      ...r,
                      description: e.target.value,
                    };
                  }
                  return r;
                });
                updateRows(updatedRows);
              }}
            />
              </div>
      </li>
    );
  });
function addRowSection(){
  updateRows([
    ...rows,
    { id: generateId(), title: "", description: "" },
  ])
}
  return (
    <>
      
        
          <ul className="commonBackGray">{rowsElements}</ul>
          <AddButton
                callFunction={addRowSection}
                opName='Add Rows'
                hidden={hidePlusButton}
                />
        

    </>
  );
};

const Sections = ({ sections, setSections }) => {
  const hidePlusButton = useMemo(() => {
    let rows = 0;
    sections.forEach((section) => {
      section.rows.forEach(() => (rows = rows + 1));
    });
    return rows > 9;
  }, [sections]);

  const updateSectionRows = (sectionId, rows) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        return {
          ...section,
          rows: rows,
        };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const sectionElements = sections.map((section,i) => {

    function deleteSection(section){
      setSections(sections.filter((s) => s.id !== section.id))
    }
    return (
      <>
      <div className="commonDeleteSection">
      <label >
          Section {i+1}
         </label>
      <DeleteButton callFunction={deleteSection} comId={section} />
       </div>
      
              <div className="labelInputFull mt-3">
                <input
                  type="text"
                  value={section.title}
                  placeholder="title"
        
                  onChange={(e) => {
                    setSections(
                      sections.map((s) => {
                        if (s.id === section.id) {
                          s.title = e.target.value;
                        }
                        return s;
                      })
                    );
                  }}
                />
              </div>
          <Rows
            rows={section.rows}
            hidePlusButton={hidePlusButton}
            updateRows={(rows) => updateSectionRows(section.id, rows)}
          />
       
        <br />
      </>
    );
  });
  function addSection(params) {
    setSections([
      ...sections,
      { id: generateId(), title: "", rows: [] },
    ])
   }
  return (
    <>

    <div >
      
      {sectionElements}
      <div className="addConditions">
              <AddButton
                callFunction={addSection}
                opName='Add Section'
                />
            </div>
      </div>
    
        
          
          {/* <div hidden={hidePlusButton}>
  
      </div> */}
    </>
  );
};

export const InteractiveListPanel = ({ id, type = "List" }) => {
  const [data, setData] = useNodeData(id);


  if (!data.sections) {
    setData({ ...data, sections: [] });
  }
  const updateSections = (sections) => {
    setData({
      ...data,
      sections: sections,
    });
  };

  return (
    <div className="right_panel_workflow">
    <SidePanelClose nodeName="List" />
      <section className="sideInnerParent">
        
       

        <div className="labelInputFull ">
        <label htmlFor="url" className="nodePanel-labels">
        {type + " Name"}
          </label>
          <div  className="labelInputFull">
          <input
            type="text"
            className="nodePanel-inputs mb-2"
            value={data.name || ""}
            maxLength={15}
            onChange={(e) => setData({ ...data, name: e.target.value })}
            placeholder={type + " Name"}
          />
            
          </div>
          <label htmlFor="url" className="nodePanel-labels">
              Body
            </label>
            <div className="msgTextArea">
            <textarea
               className="input-form"
              value={data.body}
              maxLength={1024}
              onChange={(e) => setData({ ...data, body: e.target.value })}
            ></textarea>
            </div>
        </div>
        <div>
          <label htmlFor="">Button Text</label>
          <div className="labelInputFull">
          <input
              type="text"
              className="nodePanel-inputs w-100"
              placeholder="Enter text"
              value={data.buttonText}
              maxLength={20}
              onChange={(e) =>
                setData({ ...data, buttonText: e.target.value })
              }
            />
          </div>
        </div>
        <Sections sections={data.sections} setSections={updateSections} />

        <div className="right_section_separate">
          <FallBack
            data={data.fallBack}
            update={(value) => setData({ ...data, fallBack: value })}
          />
        </div>
      </section>
    </div>
  );
};
const MAX_CHARS = 20;

const headerHeight = 60;

const InteractiveList = ({ id, data, selected }) => {
  const state = useStoreApi();
  const updateNodeInternals = useUpdateNodeInternals();

  const [, height] = getMeasurements(id, state);

  let totalRows = 0;
  (data.sections || []).forEach((s) => (totalRows += (s.rows || []).length));

  let gap = (height - headerHeight) / totalRows;

  const [handlers, rows] = useMemo(() => {
    let handlersItems = [];
    let rows = [];
    let count = 1;

    data.sections = data.sections || [];
    if (data.sections.length !== 0) {
      data.sections.forEach((section) => {
        if (section.rows !== 0) {
          section.rows.forEach((row) => {
            rows.push(row);
            handlersItems.push(
              <Handle
                id={row.id}
                type="source"
                position={Position.Right}
                className="commonHandleStyle"
              />
            );
            count += 1;
          });
        }
      });
    }
    return [handlersItems, rows];
  }, [data.sections, gap]);

  updateNodeInternals(id);

  let body = data.body || "";
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected);
  }, [selected]);
  return (
    <>
      <div
        className={
          `${"set_msgcard"} ${"commonHeader"}`
          // +
          // (data.isStartNode ? "start_node" : "") +
          // (!data.isStartNode && selected ? "selected_node" : "")
        }
        style={{
          backgroundColor: "#f6ecff",
          border: selected ? `solid 2px${"#8000FF"}` : "",
        }}
      >
        <NodeHeader
          name={"List"}
          icon={Icons.listNodeLogo}
          onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        />
        <div className="listNodeStyle">
          <div>
            <p>
              {body.length > MAX_CHARS
                ? body.substring(0, MAX_CHARS) + "...."
                : body}
            </p>
          </div>
          {rows.map((row) => {
            return (
              <div className="interactiveButtons">
                <button>{row.title}</button>
                {handlers}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default InteractiveList;
