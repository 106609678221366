import { React, useEffect, useState } from "react";
import {
  getMeasurements,
  NodeHeader,
  StartNode,
  VariablePick,
} from "../../Common";
import { generateId } from "../../../../../utils/store";
import {
  Handle,
  Position,
  useStoreApi,
  useUpdateNodeInternals,
} from "reactflow";
import { BsPlusLg } from "react-icons/bs";
import Icons from "../../../../../assets/imageUrl";
import SidePanelClose from "../../../../../Components/sidePanelClose/SidePanelClose";
import { useNodeData } from "../../../../store/selector";
import { AddButton,DeleteButton } from "../../../../CommonButtons";
const operators = ["and", "or"];

const options = [
  "base64Encode",
  "base64Decode",
  "Int",
  "Boolean",
  "Float",
  "String",
];

const SymbolMap = {
  base64Encode: "base64Encode",
  base64Decode: "base64Decode",
  Int: "Int",
  Boolean: "Boolean",
  Float: "Float",
  String: "String",
};

export const ConversionPanel = ({id, type = "Convert", vars = [] }) => {
  const [data, setData] = useNodeData(id);

  // eslint-disable-next-line no-console
  
  const conversions = data.conversions || [];
  // eslint-disable-next-line no-console
 

  const setConversions = (conversion) => {
    setData({ ...data, conversions: conversion });
  };

  function deleteConversions(conversion){ 
    setConversions(conversions.filter((c) => conversion.id !== c.id))
  }

  if (typeof conversions === "undefined") {
    setConversions([]);
  }

  // const handlevariable = (e) => {
  //     const { value, name } = e.target
  // }

  const conversionOptions = conversions.map((conversion) => {
    return (
      <li >
        <div className="commonDeleteSection">
       <label >
          Conversion
         </label>
      <DeleteButton callFunction={deleteConversions} comId={conversion}/>
       </div>
        <div>
          <VariablePick
            input={conversion.variable}
            placeholder="Variable"
            setInput={(variable) => {
              setConversions(
                conversions.map((cond) => {
                  if (cond.id === conversion.id) {
                    return {
                      ...cond,
                      variable: variable,
                    };
                  }
                  return cond;
                })
              );
            }}
            vars={vars}
          />
        </div>
        <div className="labelInputFull mt-3">
          <select
            className="right_section_select select_condition NodeConditionSelectOperator "
            onChange={(e) =>
              setConversions(
                conversions.map((cond) => {
                  if (cond.id === conversion.id) {
                    return {
                      ...cond,
                      operation: options[e.target.selectedIndex - 1],
                    };
                  }
                  return cond;
                })
              )
            }
          >
            <option>Select Operator</option>
            {options.map((option) => (
              <option selected={option === conversion.operation}>
                {SymbolMap[option]}
              </option>
            ))}
          </select>
        </div>
        <div className="labelInputFull mt-3">
          
            <input
              type="text"
              placeholder="Value"
               value={conversion?.value || ""}
              // input={conversion.value}
              onChange={(e) => {
              setConversions(
                conversions.map((cond) => {
                  if (cond.id === conversion.id) {
                    return {
                      ...cond,
                      value: e.target.value,
                    };
                  }
                 
                  return cond;
                })
              );
            }}
            // vars={vars}
            />
        </div>
        <div className="minus-condition justify-content-end mt-10">
          <i
            className="fa-solid fa-trash-can NodeConditionDelete"
            onClick={() =>
              setConversions(conversions.filter((c) => conversion.id !== c.id))
            }
          ></i>
          {/* <FiMinus
           
          /> */}
        </div>
      </li>
    );
  });

  return (
    <div className="right_panel_workflow">
      <SidePanelClose nodeName="Conversion" />
<section className="sideInnerParent">
<div>
<label className="nodePanel-labels">{type + " Name"}</label>
<div className="labelInputFull ">
        <input
          type="text"
          className="nodePanel-inputs mb-2"
          value={data.name || ""}
          maxLength={15}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          placeholder={type + " Name"}
        />
      </div>
</div>

<div>
<label htmlFor="url" className="nodePanel-labels">
                Conversions
              </label>

              <ul className="commonBackGray">{conversionOptions}</ul>
              <div className="addConditions">
              <AddButton
                callFunction={()=> setConversions([
                  ...conversions,
                  { id: generateId(), value: "", variable: "" },
                ])}
                opName='Add Conversions'
                />
            </div>
</div>

    
     
        {/* converion name and plus symbol */}
        

        
        {conversions.length > 1 && (
          <li >
             <div className="commonDeleteSection">
       {/* <label >
          Condition
         </label> */}
      
       </div>
              {/* <label htmlFor="url" className="nodePanel-labels mb-2">
                Operator
              </label>
           

            <div>
              <div className="labelInputFull">
              <select
                className="right_section_input w-100 nodePanel-inputs"
                onChange={(e) =>
                  setData({ ...data, operator: e.target.value })
                }
              >
                <option className="nodePanel-inputs">Select Operator</option>
                {operators.map((operator) => (
                  <option selected={operator === data.operator}>
                    {operator}
                  </option>
                ))}
              </select>
              </div>
             
            </div> */}
          </li>
        )}
   
      </section>
    </div>
  );
};

const Conversion = ({ id, data, selected }) => {

  const store = useStoreApi();
  const updateNodeInternals = useUpdateNodeInternals();

  updateNodeInternals(id);

  const [, height] = getMeasurements(id, store);
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected);
  }, [selected]);
  return (
    <>
      <div
        style={{
          backgroundColor: "#ffecec",
          border: selected ? `solid 2px${"#FF9900"}` : "",
        }}
        className={
          "set_msgcard " +
          // (data.isStartNode ? "start_node " : "") +
          // (!data.isStartNode && selected ? "selected_node " : "" )
          "commonHeader"
        }
      >
        <NodeHeader
          name={"Conversion"}
          icon={Icons.conditionNodeLogo}
          onDelete={data.delete}
          selected
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        />
        <div className="conversionNodeStyle">
          <div>
            <p>Post</p>
            <Handle
              id="yes"
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div>
          {/* <div>
            <p>No</p>
            <Handle
              id="no"
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Conversion;
