import { commonRequestV1 } from "./common";

const path = (groupId) => "/workflows/" + groupId;

export const getGroups = (
  limit = 10,
  skip = 0,
  search = "",
  sortOrder = "desc",
  sortBy = "created_at",
  filter = ""
) => {
  return commonRequestV1(
    "get",
    path(
      `?limit=${limit}&skip=${skip}&search=${search}&filter=${filter}&sortOrder=${sortOrder}&sortBy=${sortBy}`
    ),
    {}
  );
};

export const getGroup = (groupId) => {
  return commonRequestV1("get", path(groupId), {});
};

export const createGroup = (group) => {
  return commonRequestV1("post", path(""), group);
};

export const updateGroup = (group) => {
  return commonRequestV1("put", path(group._id), group);
};

export const deleteGroup = (groupId) => {
  return commonRequestV1("delete", path(groupId), {});
};

export const searchGroups = (search) => {
  return commonRequestV1("get", path(`?search=${search}`), {});
};

export const filterGroups = (filter) => {
  return commonRequestV1("get", path(`?filter=${filter}`), {});
};
