import { generateId } from "../../utils/store";

export const defaultSettings = {
  brand: {
    theme: "light", // or black,
    logo: {
      black: "",
      white: ""
    },
    colors: {
      button: {
        primary: "#525f7f",
        secondary: "#525f7f",
        textPrimary: "#525f7f",
        textSecondary: "#525f7f",
        borderRadius: 6,
        boxShadow: 6,
      },
      input: {
        background: "#525f7f",
        label: "#525f7f",
        value: "#525f7f",
        placeHolder: "#525f7f",
        border: "#525f7f",
        borderRadius: 6,
        boxShadow: 6,
        focusShadow: 6,
        focusBorder: "#525f7f",
        focus: "#525f7f"

      },
      text: {
        primary: "#525f7f",
        secondary: "#525f7f"

      }
    },
    fonts: {
      H1: {
        size: 32,
        family: "Arial",
        weight: 600
      },
      H2: {
        size: 28,
        family: "Arial",
        weight: 600
      },
      H3: {
        size: 24,
        family: "Arial",
        weight: 600
      },
      subtitle1: {
        size: 20,
        family: "Arial",
        weight: 600
      },
      subtitle2: {
        size: 16,
        family: "Arial",
        weight: 600
      },
      subtitle3: {
        size: 12,
        family: "Arial",
        weight: 600
      },
      body: {
        size: 12,
        family: "Arial",
        weight: 400
      },
    },
  },
  portal: {
    language: "english",
    faviconUrl: "",
    coverImg: "",
    meta: {
      title: "{{flowName}}",
      description: "Made with ID Flow"
    }
  }
}

const defaultStartNode = () =>({
  id: generateId(),
  type: "StartNode",
  data: {},
  position: {
    x: 250,
    y: 250
  }
})
export const defaultWorkFlow = (type) => ({
  id: "",
  name: "New Flow",
  nodes: type && type ==="api" ?  []: [defaultStartNode()],
  edges: [],
  extra: {},
  viewport: {
    x: 0,
    y: 0,
    zoom: .5
  }
})



