import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";

import NavBar from "../Components/NavBar";


import { useQuery } from "react-query";
import Resource from "../api/resource";
import { getQueryString, useFullScreen } from "./hooks";
import { useActiveSelectors } from "./store/activeStore";
import { useCustomNodes, useFromSettings, useFromWorkFlow, useSetToDefault } from "./store/selector";

const crud = Resource("workflows");

export default function WorkFlowBuilder() {
  useFullScreen()

  const [rfInstance, setRfInstance] = useState(null);
  const active = useActiveSelectors.use.active();

  const setToDefault = useSetToDefault();
  const fromWorkFlow = useFromWorkFlow();
  const fromSettings = useFromSettings();
  const [_, setNodes] = useCustomNodes();

  const params = useParams();
  const type = getQueryString("type");

  useEffect(() =>{
    const g = async () => {
      let workflowId = params.workflowId;
      if (workflowId === "new"){
        return setToDefault(type)
      }
      let workflow =  await crud.get(workflowId)
      fromWorkFlow(workflow);
      fromSettings(workflow.settings)

    }
    g();
  }, [])



  const { isLoading: nodesLoading, error: nodesError } = useQuery("nodes", async () => {
    let nodes = await Resource("nodes").getAll(0, 10000, "_id,name,category,logo,sub_category,organization,inputs,outputs");
    setNodes(nodes);
  });

  const isLoading = nodesLoading;

  return (
    <ReactFlowProvider>
      <div className="mainWorkflowCont">
        <NavBar rfInstance={rfInstance} title="Draft" rightBtn="" />
        <Outlet context={{ rfInstance, setRfInstance, isLoading }} />
      </div>
    </ReactFlowProvider>
  );
}