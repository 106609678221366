import React, {useCallback, useRef} from "react";

import "react-html5-camera-photo/build/css/index.css";
import {emptyProps, FullContainer, Node} from "../common";
import {registerComponent} from "../components";
import {useEvents, useIsDebug, useIsEditor} from "../../../src/stores/context";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import Webcam from "react-webcam";
import {nameInput} from "./input";
import {removeBasePath} from "../../../src/stores/util";

const type = "Camera";

export const inputs = [
    nameInput,
    {
        name: "Face Camera / Rear Camera",
        value: "cameraMode",
        type: "toggle",
        defaultValue: false
    },
    {
        name: "Mirror Image",
        value: "mirrorImage",
        type: "toggle",
        defaultValue: false
    },
    {
        name: "Image Width",
        type: "numberInput",
        value: "imageWidth",
        defaultValue: 600,
        requried: true,
        autoFocus: true,
        placeHolder: "Enter ...!",
    },
    {
        name: "Image Height",
        type: "numberInput",
        value: "imageHeight",
        defaultValue: 400,
        requried: true,
        autoFocus: true,
        placeHolder: "Enter ...!",
    },
    {
        name: "Title",
        type: "textInput",
        value: "title",

        requried: true,
        autoFocus: true,
        placeHolder: "Enter ...!",
    },
    {
        name: "Description",
        value: "description",
        type: "textInput",
        placeHolder: "Enter ...!",
    },

    FullContainer,
];

export const CameraUpload = {
    name: "CameraUpload",
    type,
    props: emptyProps,
};

export const CameraUploadNode = () => {
    return (
        <Node type={type}>
            <div className="page-side-node br-right">
                <img src={PageBuilderAssets?.cameraIcon} className="node-img"/>
                <p className="node-name mb-2">Camera</p>
            </div>
        </Node>
    );
};

export const CameraUploadPlay = ({
                                     id,
                                     name,
                                     title,
                                     description,
                                     imageWidth = 600,
                                     imageHeight = 400,
                                     mirrorImage = false,
                                     cameraMode = false

                                 }) => {
    let width = parseInt(imageWidth, 10);
    let height = parseInt(imageHeight, 10);
    let debugMode = useIsDebug();

    let editor = useIsEditor();
    const {formSubmit} = useEvents();
    const ref = useRef();

    const handleCapture = useCallback(() => {
        const image = ref.current.getScreenshot({height, width});
        formSubmit(id, {[name]: removeBasePath(image)})
        // setCaptureImage(image)
    }, [ref])

    const handleFileUpload = e => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64String = event.target.result;
                formSubmit(id, {[name]: removeBasePath(base64String)})
            };
            reader.readAsDataURL(file);
        }
    }


    // const handleClearImage = () => setCaptureImage(undefined);

    return (
        <>
            <div>
                <div>
                    {editor ?
                        <>
                            <div className="statCamera">
                                <img src={PageBuilderAssets?.staticCamera}/>
                                <p>Camera will appear here</p>
                            </div>
                        </>
                        :
                        <Webcam
                            audio={false}
                            ref={ref}
                            height={height}
                            width="100%"
                            screenshotQuality={1}
                            screenshotFormat="image/jpeg"
                            forceScreenshotSourceSize={true}
                            mirrored={mirrorImage}
                            videoConstraints={{
                                width: height,
                                height: width,
                                facingMode: {exact: debugMode ? "user" : cameraMode ? "environment" : "user"}
                            }}
                        />
                    }
                    <div className="camBottom">
                        {title && <p className="mb-0 clr-w">{title}</p>}
                        {description && <p>{description}</p>}
                        <div className="capture_btns_wrap">
                            <label for="fileInput">
                                <img src={PageBuilderAssets?.uploadIcon} alt="Upload" className="upload-icon"/>
                            </label>
                            <input
                                type="file"
                                accept=""
                                id="fileInput"
                                onChange={handleFileUpload}
                                disabled={editor}
                                style={{display: 'none'}}
                            />
                            <img src={PageBuilderAssets?.cameraShutterIcon}
                                 onClick={!editor && handleCapture} disabled={editor}/>
                            <img src={PageBuilderAssets?.cameraCloseIcon} style={{visibility: "hidden"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

registerComponent(type, {
    node: CameraUploadNode,
    play: CameraUploadPlay,
    item: CameraUpload,
    inputs,
    category: "Inputs",
});