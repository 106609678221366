import React, { useEffect, useRef, useState } from "react";
import { useForm, useIsDebug, useIsEditor } from "../../../src/stores/context";
import { emptyProps, FullContainer, Node } from "../common";
import { registerComponent } from "../components";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import "react-image-crop/dist/ReactCrop.css";
import { nameInput } from "../inputs/input";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { getURL, removeBasePath } from "../../../src/stores/util";
import * as Yup from "yup";

export const type = "ImageCrop";

export const inputs = [
  nameInput,
  {
    name: "Image Source",
    value: "image",
    type: "textInput",
    required: true,
    autoFocus: true
  },
  FullContainer
];


export const ImageCrop = {
  name: "Image Crop",
  type,
  props: emptyProps
};

export const ImageCropNode = () => {
  return (
    <Node type={type}>
      <div className="page-side-node br-right">
        <img src={PageBuilderAssets?.imageCrop} className="node-img" />
        <p className="node-name mb-2">Image Crop</p>
      </div>
    </Node>
  );
};


export function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fn();
    }, waitTime);

    return () => {
      clearTimeout(timeoutId);
    };
  }, deps);
}


const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}


function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export const ImageCropPlay = ({
  id,
  name,
  image
}) => {
  const editor = useIsEditor();

  const { isForm, formik, registerValidator } = useForm();

  const [crop, setCrop] = useState(undefined);
  const [aspect, setAspect] = useState(16 / 9);
  const imgRef = useRef(null);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);
  const previewCanvasRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState();

  const setValue = v => {
    formik.setFieldValue(name, removeBasePath(v));
  }

  const onImageLoad = e => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, crop, scale, rotate]
  );

  useEffect(() => {
    if (!editor && isForm) {
      registerValidator(name, Yup.string())
    }
  }, []);


  useEffect(() => {
    if (!editor && isForm) {
      setValue(image);
    }
  }, []);



  useEffect(() => {
    if (previewCanvasRef.current && isForm) {
      let image = previewCanvasRef.current.toDataURL();
      setValue(image);
    }
  }, [crop, rotate, completedCrop,])

  return (
    <>
      <div
        className="PBmainheadform"
      >
        <div className="imageCropdiv">
          {editor ?
            <>
              <div className="cropTemp" >

                <img src={PageBuilderAssets?.cropTemp} />
              </div>
            </>

            : <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={c => setCompletedCrop(c)}>
              <img
                src={getURL(image)}
                ref={imgRef}
                style={{ transform: `rotate(${rotate}deg)` }}
                onLoad={onImageLoad} />
            </ReactCrop>}
        </div>

        <div className="rotation">
          <div className="d-flex align-content-center justify-content-center">
            <img src={PageBuilderAssets?.rotateIcon} /> &nbsp;
            <h6 className="mb-0">Rotate</h6>
          </div>
          <p className="mb-0">360&#176;</p>
        </div>
        <div className="PB-range-slider-div">
          <input id="myRange" className="PB-range-slider mb-0" min="0"
            max="360"
            disabled={editor}
            onChange={e => {
              e.stopPropagation();
              e.preventDefault();
              setRotate(parseInt(e.target.value) - 180);
            }}
            type="range" />
        </div>
        {!!completedCrop && <div>
          <canvas
            ref={previewCanvasRef}
            style={{
              display: "none",
              border: "1px solid black",
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height
            }}
          />
        </div>}
      </div>
    </>
  );
};


registerComponent(type, {
  item: ImageCrop,
  play: ImageCropPlay,
  node: ImageCropNode,
  inputs,
  isInput: true,
  category: "Images"
});
