import React, { useEffect, useState } from "react";
import { getMeasurements, NodeHeader, StartNode } from "../../Common";
import { JsonEditor } from "jsoneditor-react";
import { Tick } from "../../../../logourls";
import { FiCopy } from "react-icons/fi";
import { Handle, Position, useStoreApi, useUpdateNodeInternals } from "reactflow";
import { TbWebhook } from "react-icons/tb";
import Icons from "../../../../../assets/imageUrl";
import SidePanelClose from "../../../../../Components/sidePanelClose/SidePanelClose";
import { useNodeData } from "../../../../store/selector";
import { webHookColor } from "../../../../../assets/imageUrl";
const MAX_CHARS = 15;

const nodeNameWebhook = "Webhook";


export const WebhookPanel = ({ id, type = "Webhook", flowId }) => {
    // const [urlCopy, setUrlCopy] = useState("")
    const [data, setData] = useNodeData(id);
    
    const [tickTrue, setTickTrue] = useState(false)

    useEffect(() => {
        if (tickTrue) {
            setTimeout(() => setTickTrue(false), 1000);
        }
    }, [tickTrue])

    const handleCopy = () => {
        navigator.clipboard.writeText(`https://hook.idflow.com/V1/workflows/${flowId}/${id}`);
        setTickTrue(true)
    }
    return (
        <div className="right_panel_workflow">
            <SidePanelClose nodeName={nodeNameWebhook} />
            <section className="sideInnerParent">
                <div>
                <label className="nodePanel-labels">{type + " Name"}</label>
<div className="labelInputFull">

<input
    type="text"
    className="nodePanel-inputs mb-2"
    value={data.name || ""}
    maxLength={15}
    onChange={(e) => setData({ ...data, name: e.target.value })}
    placeholder={type + " Name"}
/>
</div>
                </div>
                <div>
                <label htmlFor="body" className="nodePanel-labels">
                    Fields
                </label>
                <span className="alertRequired">required *</span>
                <JsonEditor
                    mode="text"
                    value={data.fields || {}}
                    onChange={(value) => setData({ ...data, fields: value })}
                />

<label className="nodePanel-labels mt-3">POST URL</label>
<div className="labelInputFull">
<input type="text" className="form-control cus_input_webhk"
                        onChange={e => setData({ ...data, url: e.target.value })}
                        value="https://hook.idflow.com/V1/workFlows/{{id}}" ></input>
</div>
{/* {tickTrue ? : <FiCopy  onClick={handleCopy} />}            */}
   <div onClick={handleCopy} className="selCopyText">Copy URL  <span>{tickTrue ? <img src={Tick} alt="image"  /> :<FiCopy   />} </span>  </div>
                
                </div>
                
                    
                    <div>
                    <h6 className="NoteNode">Note : </h6>
                     <p className="NoteMsg">After clicking the "Save" button, a unique URL will be generated, enabling you to access and share the saved workflow effortlessly.</p>
                    </div>
                
            </section>
            
            {/* <hr className="accordion_hr" /> */}
         
        </div>
    );
};

export function Webhook({ id, data, selected }) {
    const [accessSelected, setAccessSelected] = useState(selected);

    useEffect(() => {
        setAccessSelected(selected)
    }, [selected])
    return (
        <div

            className={
                "App "

                // +
                // (data.isStartNode ? "start_node" : "") +
                // (!data.isStartNode && selected ? "selected_node" : "")

            }
        >
            <div
                style={{ backgroundColor: "#E8F4FF", border: selected ? `solid 2px${"#00AAFF"}` : "" }}
                className={`${"set_msgcard"} ${"commonHeader"}`}>
                <NodeHeader
                    name={nodeNameWebhook}
                    icon={webHookColor}
                    onDelete={data.delete}
                    accessSelected={accessSelected}
                    noHandle
                />

                <div className="webHookNodeStyle">
                    <div>

                       

                                <p>{data.name || "Post"}</p>
                                {/* <p>
                                    {data.url.length > MAX_CHARS
                                        ? data.url.substring(0, MAX_CHARS) + "..."
                                        : data.url}
                                </p> */}
                           


                     
                        <Handle
                            type="source"
                            position={Position.Right}
                            className="commonHandleStyle"
                        />
                    </div>
                </div>
            </div>

        </div>
    );
}