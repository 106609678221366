import { getMeasurements, NodeHeader, VariablePick } from "../../Common";
import React, { useEffect, useState } from "react";
import { generateId } from "../../../../../utils/store";
import { parseInt } from "lodash";
import { Handle, Position, useStoreApi, useUpdateNodeInternals } from "reactflow";
import { useCustomNodes, useNodeData } from "../../../../store/selector";
// import { getMeasurements } from "../../../../FlowBuilder/Flow/Nodes/Common/Common";
import SidePanelClose from "../../../../../Components/sidePanelClose/SidePanelClose";
import Icons from "../../../../../assets/imageUrl";

export const ExecuteAPIPanel = ({ id, type, vars }) => {

  const [data, setData] = useNodeData(id);
  console.log(data)

  const [nodes, _] = useCustomNodes();

  const node = nodes.find(node => node.id === data.nodeId);


  if (data.inputs === undefined) {
    setData({
      ...data,
      inputs: node.inputs.map((input) => {
        return { id: generateId(), name: input, value: undefined };
      })
    });
  }
  if (data.outputs === undefined) {
    setData({
      ...data,
      outputs: node.outputs.map((output) => {

        return {
          id: generateId(),
          status: output.status,
          outputs: output.outputs.map((output) => ({
            id: generateId(),
            name: output,
            value: undefined
          }))
        };
      })
    });
  }

  let inputs = data.inputs || [];
  let outputs = data.outputs || [];

  return (
    <div className="right_panel_workflow">
      <SidePanelClose nodeName={node?.name} />
      <section className="sideInnerParent">

        {inputs.length > 0 ?
          <>
            <div>
              <label htmlFor="" className="nodePanel-labels classDarkHeader">Inputs:</label>
              {inputs.map((item, index) => (
                <div key={item.id}>
                  <label className="nodePanel-labels">{item.name}</label>
                  <VariablePick input={item.value} setInput={(value) => setData({
                    ...data,
                    inputs: inputs.map((i) => {
                      if (i.id === item.id) {
                        return {
                          ...i,
                          value: value
                        };
                      }
                      return i;
                    })
                  })} vars={vars} />
                </div>

              ))}
            </div>

          </>


          : <></>

          // (
          //   <>
          //     <div className="d-flex justify-content-center">
          //       <img src={nodata} alt="nodata" className="w-80p" />
          //     </div>
          //     <p className="noDataNode">No Inputs found</p>
          //   </>
          // )

        }
        {outputs.length > 0 ? (
          <div>
            <label htmlFor="" className="nodePanel-labels classDarkHeader">Outputs:</label>
            {
              outputs.map((output, index) => {

                return (
                  <ul className="commonBackGray" key={index}>


                    {output.outputs.length > 0 ? (
                      output.outputs.map((item) => (
                        <li key={item.id}>
                          <div>
                            <label htmlFor="">Status :- {output.status}</label>
                          </div>
                          <div>
                            <label className="nodePanel-labels mt-3" htmlFor="">{item.name}</label>
                            <div className="labelInputFull">
                              <input
                                type="text"
                                value={item.value}
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    outputs: data.outputs.map((o) => {
                                      if (o.id === output.id) {
                                        o = {
                                          ...o,
                                          outputs: o.outputs.map((i) => {
                                            if (i.id === item.id) {
                                              return {
                                                ...i,
                                                value: e.target.value
                                              };
                                            }
                                            return i;
                                          })
                                        };
                                      }
                                      return o;
                                    })
                                  })
                                }
                              />
                            </div>

                          </div>

                        </li>
                      ))
                    ) : (
                      <>
                      </>
                    )}
                  </ul>
                );
              })
            }
          </div>

        ) : (
          <>
          </>
        )}

      </section>
      {/* <div className="flow-name right_section_separate">
        <div className="d-flex DataExNode">
          <img src={nodeImg} alt="" className="nodeImage" />
          <p className="flow-name-head InputsTitle m-0 p-0 NodeCustomTitle">
            {node?.name}
          </p>
        </div>

        <div className="d-flex justify-content-between">
          <h3 className="InputsTitle">
            <i class="fa-regular fa-circle-dot DotIcon"></i> Inputs
          </h3>
          <h3 className="InputsTitle">
            <i class="fa-regular fa-circle-dot DotIcon"></i> Variables
          </h3>
        </div>

       
        <h3 className="InputsTitle">Outputs : </h3>
        
        <ul className="noList">
          
        </ul>
      </div> */}
    </div>
  );
};

const ExecuteAPINode = ({ id, data, selected }) => {
  // const { nodes } = useLoaderData();
  const [nodes, _] = useCustomNodes();

  const store = useStoreApi();
  const updateNodeInternals = useUpdateNodeInternals();
  const [, height] = getMeasurements(id, store);

  // let gap = height / ((data.outputs || []).length + 1);

  const node = nodes.find((node) => node.id === data.nodeId);

  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected);
  }, [selected]);


  return (
    <div
      style={{ backgroundColor: "#ffecec", border: selected ? `solid 2px${"#FF9900"}` : "" }}
      className={`${"set_msgcard"} ${"commonHeader"}`}
    >
      <NodeHeader
        name={node?.name}
        icon={Icons.conditionNodeLogo}
        selected
        accessSelected={accessSelected}
        // isStartNode={data.isStartNode}
      ></NodeHeader>

<div className="conditionNodeStyle">

        {(data.outputs || []).map((output, index) => (
          <div>
<p>{output.status}
</p>
 <Handle
          id={output.id}
          type="source"
          position={Position.Right}
          className="commonHandleStyle"
          style={{
            background: colorCode(parseInt(output.status))
          }}
        />
       
          </div>
            
        ))}
   </div>
      
    </div>

  );
};

const colorCode = (statusCode) => {
  switch (Math.floor(statusCode / 100)) {
    case 2:
      return "green";
    case 3:
      return "orange";
    case 4:
      return "red";
    case 5:
      return "pink";
    default:
      return "";
  }
};

export default ExecuteAPINode;
