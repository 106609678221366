import React, { useState } from "react";
import BoxIcon from "../../../assets/portalsetting/box-icon.svg";
import DisplayIcon from '../../../assets/portalsetting/dispIcon.svg';
import BoxIcon120 from "../../../assets/portalsetting/box-icon120.svg";
import CoverComp from "../../../assets/portalsetting/coverComp";
import ImgComp from "../../../assets/portalsetting/imgComp";
import LandscapeComp from "../../../assets/portalsetting/landscapeComp";
import UploadIcon from "../../../assets/Upload_icon.svg";
import './index.css';
import { usePortalSettings } from "../../../WorkFlowBuilder/store/selector";
import PortalLoader from "./PortalLoader/PortalLoader";
import CustomButton from "../../core/Button";
import CustomInput from "../../core/InputField";
import CustomSelect from "../../core/CustomSelect";
import MadeInIdFlowDark from "../../../assets/made-in-idflow-dark.svg"
import MadeInIdFlowLight from "../../../assets/made-in-idflow-light.svg"
import CheckCircle from '../../../assets/solar_check-circle-bold.svg';
import Upload_icon from "../../../assets/Upload_icon.svg";


export default function PortalSetting() {

  const [portal, setPortal] = usePortalSettings();
  const [loading, setLoading] = useState(false);

  const handleWebCover = (event) => {
    if (event.target.files?.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setPortal({ ...portal, coverImg: base64String })
        }
        reader.readAsDataURL(file)
      }
    }
  };

  const handleChangeFavicon = (event) => {
    if (event.target.files?.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setPortal({ ...portal, favicon: base64String })
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const handleChangeDispalyIcon = (event) => {
    if (event.target.files?.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setPortal({ ...portal, displayIcon: base64String })
        }
        reader.readAsDataURL(file)
      }
    }
  }
  const languageOptions = [
    {value:"English",label:"English"},
    {value:"Hindi",label:"Hindi"},
    {value:"Tamil",label:"Tamil"}
  ]
  const positionOptions = [
    {value:"Top Right Corner",label:"Top Right Corner"},
    {value:"Top Left Corner",label:"Top Left Corner"},
    {value:"Bottom Right Corner",label:"Bottom Right Corner"},
    {value:"Bottom Left Corner",label:"Bottom Left Corner"}
  ]
  const [activeTheme, setActiveTheme] = useState('')
  const [activeLoader, setActiveLoader] = useState('')
  const [uploadFileName, setUploadFileName] = useState('')

  return (
    <div className="main-portalsettings">
      {loading === true ? <PortalLoader /> :
      <div className="portal_setting_wrapper">
        <div className="title-sec">
          <div className="context_wrapper">
            <p className="g-title">Portal Setting</p>
            <p className="desc">Apply your unique business branding to maintain a familiar and recognizable experience for your users.</p>
          </div>
        </div>
        <div className="lang-sec row">
          <div className="col-lg-5 left_sec">
            <p className="lang-title">Language</p>
            <p className="desc">Please select the web portal Language</p>
          </div>
          <div className="col-lg-7 right_sec">
            <CustomSelect isSearchable={false} label="Select Language" options={languageOptions} />
          </div>
        </div>
        <div className="favi-sec row">
          <div className="col-lg-5 left_sec">
            <p className="lang-title">Favicon</p>
            <p className="desc">Your favicon must be square 64x64 .png</p>
            {portal.favicon?.length > 0 ? (
              <img
                className="image"
                src={portal.favicon}
                height={80}
                width={80}
                alt="favicon"
              />
            ) : (
              <img className="image1" src={BoxIcon} alt="box" />
            )}
            <div className="upload_div">
              <label class="upload_btn" for="favicon-upload">
                <input onChange={handleChangeFavicon} type="file" id="favicon-upload" style={{ display: "none" }} accept="image/png" />
                <img src={UploadIcon} alt="upload" />
                Upload file
              </label>
            </div>
          </div>
          <div className="col-lg-7 right_sec">
            <div className="img_preview">
              <p className="img-title"> Preview</p>
              <ImgComp imgSrc={portal?.favicon} alt="fav" />
            </div>
          </div>
        </div>
        <div className="web-sec row">
          <div className="col-lg-5 left_sec">
            <p className="lang-title">Website cover image</p>
            <p className="desc">Customize your Sites Cover Image </p>
            {portal?.coverImg?.length > 0 ? (
              <img
                className="image"
                src={portal?.coverImg}
                alt="cover"
                width={80}
              />
            ) : (
              <img
                className="image"
                src={BoxIcon120}
                alt="bixx"
              />
            )}

            <div className="upload_div">
              <label class="upload_btn" for="webcover-upload">
                <input onChange={handleWebCover} type="file" accept="image/png" style={{ display: "none" }} id="webcover-upload" />
                <img src={UploadIcon} alt="upload-icon" />
                Upload file
              </label>
            </div>
          </div>
          <div className="col-lg-6 right_sec">
            <div className="img_preview">
              <p className="img-title"> Preview</p>
              {portal.coverImg?.length > 0 ? (
                <div className="image">
                  <CoverComp imgSrc={portal.coverImg} alt="cover" />
                </div>
              ) : (
                <div className="">
                  <CoverComp imgSrc={portal.coverImg} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="web-sec row">
          <div className="col-lg-5 left_sec">
            <p className="lang-title">Metadata</p>
            <p className="desc">Add Title and Description for the cover image.</p>
            <div className="display_icon_wrapper">
              <p className="lang-title">Display Icon</p>
              {portal.displayIcon?.length > 0 ? (
                <img
                  className="image"
                  src={portal.displayIcon}
                  height={80}
                  width={80}
                  alt="displayIcon"
                />
              ) : (
                <img className="image1" src={DisplayIcon} alt="box" />
              )}
              <div className="upload_div">
                <label class="upload_btn" for="display-upload">
                  <input onChange={handleChangeDispalyIcon} type="file" id="display-upload" style={{ display: "none" }} accept="image/png" />
                  <img src={UploadIcon} alt="upload" />
                  Upload file
                </label>
              </div>
            </div>

            <div className="input_wrapper">
              <div className="form_fields_wrapper">
                <CustomInput
                  label="Meta title"
                  placeholder="Enter title"
                  onChange={(e) => setPortal({ ...portal, metaTitle: e.target.value })}
                />
                <p className="desc txt-grey-3">Recommended length: 60 characters</p>
              </div>
              <div className="form_fields_wrapper">
                <CustomInput
                  label="Meta URL"
                  placeholder="https://idflow.com"
                  onChange={(e) => setPortal({ ...portal, metaURL: e.target.value })}
                />
              </div>
              <div className="form_fields_wrapper">
                <textarea
                  type="textarea"
                  label="Meta Description"
                  placeholder="Enter description"
                  className="input-field textarea"
                  onChange={(e) => setPortal({ ...portal, subHeading: e.target.value })}
                />
                <p className="desc txt-grey-3">Recommended length: 155 - 160 characters</p>
              </div>
            </div>
            {/* <div>
              <p className="input-title">Meta Title</p>
              <input
                onChange={(e) => setPortal({ ...portal, metaTitle: e.target.value })}
                className="input-field"
                placeholder="Enter title"
              />
            </div>
            <div>
              <p className="input-title">Meta URL</p>
              <input
                onChange={(e) => setPortal({ ...portal, metaURL: e.target.value })}
                className="input-field mb-4"
                placeholder="https://idflow.com"
              />
            </div>
            <div>
              <p className="input-title">Meta Description</p>
              <textarea
                onChange={(e) => setPortal({ ...portal, subHeading: e.target.value })}
                className="input-field2 mt-2"
                placeholder="Enter description"
              />
              <p className="desc txt-grey-3">Recommended length: 155 - 160 characters</p>
            </div> */}
          </div>
          <div className="col-lg-7 right_sec">
            <div className="img_preview">
              <p className="img-title"> Preview</p>
              <div className="image1">
                <LandscapeComp title={portal?.meta?.title} desc={portal?.meta?.description} />
              </div>
            </div>
          </div>
        </div>
        <div className="waterflowMark-sec">
          <div className="context_wrapper">
            <p className="lang-title">ID flow Watermark</p>
            <p className="desc">Tailor your watermark variations and placement according to your preferences.</p>
          </div>
          <div className="card_wrapper">
            <div className="water_mark_card" onClick={() => setActiveTheme("blue")}>
              <div className="img_wrapper">
                <img src={MadeInIdFlowDark} alt="MadeInIdFlowDark" />
              </div>
              <div className="theme_selection">
                <div className="theme_name">Blue theme{activeTheme === "blue" && "(Active)"}</div>
                {activeTheme === "blue" && <img src={CheckCircle} alt="check circle" />}
              </div>
            </div>
            <div className="water_mark_card" onClick={() => setActiveTheme("light")}>
              <div className="img_wrapper">
                <img src={MadeInIdFlowLight} alt="MadeInIdFlowLight" />
              </div>
              <div className="theme_selection">
                <div className="theme_name">Light theme{activeTheme === "light" && "(Active)"}</div>
                {activeTheme === "light" && <img src={CheckCircle} alt="check circle" />}
              </div>
            </div>
            <div className="water_mark_card" onClick={() => setActiveTheme("dark")}>
              <div className="img_wrapper">
                <img src={MadeInIdFlowDark} alt="MadeInIdFlowDark" />
              </div>
              <div className="theme_selection">
                <div className="theme_name">Dark theme{activeTheme === "dark" && "(Active)"}</div>
                {activeTheme === "dark" && <img src={CheckCircle} alt="check circle" />}
              </div>
            </div>
          </div>
          <div className="water_mark_position">
            <CustomSelect isSearchable={false} label="Position of Watermark" options={positionOptions} />
          </div>
        </div>
        <div className="loader_animation-sec">
          <div className="context_wrapper">
            <p className="lang-title">Loader Animation</p>
            <p className="desc">Choose a loader animation from our list or import your own animation.</p>
          </div>
          <div className="loader_category row">
            <div className="col-lg-6 left_sec">
              <div className="card_wrapper">
                <div className="loader_card" onClick={() => setActiveLoader("one")}>
                  <div className="loader_div"></div>
                  <div className="loader_selection">
                    <div className="theme_name">Loader one{activeLoader === "one" && "(Active)"}</div>
                    {activeLoader === "one" && <img src={CheckCircle} alt="check circle" />}
                  </div>
                </div>
                <div className="loader_card" onClick={() => setActiveLoader("two")}>
                  <div className="loader_div"></div>
                  <div className="loader_selection">
                    <div className="theme_name">Loader two{activeLoader === "two" && "(Active)"}</div>
                    {activeLoader === "two" && <img src={CheckCircle} alt="check circle" />}
                  </div>
                </div>
                <div className="loader_card" onClick={() => setActiveLoader("three")}>
                  <div className="loader_div"></div>
                  <div className="loader_selection">
                    <div className="theme_name">Loader three{activeLoader === "three" && "(Active)"}</div>
                    {activeLoader === "three" && <img src={CheckCircle} alt="check circle" />}
                  </div>
                </div>
                <div className="loader_card" onClick={() => setActiveLoader("four")}>
                  <div className="loader_div"></div>
                  <div className="loader_selection">
                    <div className="theme_name">Loader four{activeLoader === "four" && "(Active)"}</div>
                    {activeLoader === "four" && <img src={CheckCircle} alt="check circle" />}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 right_sec">
              <div className="upload_loader">
                <label className="upload_label">
                  <p className="label_text">
                    {uploadFileName? uploadFileName : "Please upload or drag and drop your loader animation here"}
                  </p>
                  <input type="file" className="upload_input" accept="" onChange={(event)=>{
                    event.target.files[0]?
                    setUploadFileName(event.target.files[0]?.name):setUploadFileName('')
                  }} />
                  <div className="btn_wrapper">
                    <CustomButton
                      title="Upload Animation"
                      isLeft={true}
                      icon={Upload_icon}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="portal_setting_cta">
          <CustomButton title="Cancel" type="Outlined"/>
          <CustomButton title="Save changes"/>
        </div>
      </div>
      }
    </div>
  );
}
