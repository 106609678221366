import { NodeHeader, StartNode,VariablePick } from "../../Common";
import { TbVariable } from "react-icons/tb";
import { Items } from "../APICall/Apicall";
import {
  Handle,
  Position,
} from "reactflow";
import React, { useState, useEffect } from "react";
import Icons from "../../../../../assets/imageUrl";
import SidePanelClose from "../../../../../Components/sidePanelClose/SidePanelClose";
import { useNodeData } from "../../../../store/selector";
import {AddButton,DeleteButton} from '../../../../CommonButtons'
import { generateId } from "../../../../../utils/store";
const nodeName = "SwitchNode";




export const SwitchNodePanel = ({ id, type = nodeName, vars = [] }) => {
    
  
  const [data, setData] = useNodeData(id);

console.log(data,"Got the data")
  const options = [
    "Equal",
    "NotEqual",
    "GreaterThan",
    "LessThan",
    "GreaterThanorEqual",
    "LessThanorEqual",
  ];
  
  const SymbolMap = {
    Equal: "=",
    NotEqual: "!=",
    GreaterThan: ">",
    LessThan: "<",
    GreaterThanorEqual: ">=",
    LessThanorEqual: "=<",
  };

  const switchCases = data.switchCases || [];

  const setSwitchCases = (SwitchCase) => {
    setData({ ...data, switchCases: SwitchCase });
  };

  if (typeof switchCases === "undefined") {
    setCases([]);
  }

  function deleteSwitchCases(switchCase){ 
    setSwitchCases(switchCases.filter((c) => switchCase.id !== c.id))
  }

  function callAddSwitchCases(){
    setSwitchCases([
      ...switchCases,
      { id: generateId(), value: "" },
    ])
  }

  const SwitchCasesOptions = switchCases.map((switchCase,i) => {
    

    return (

      <li >
      
      <div className="commonDeleteSection">
       <label >
          Case
         </label>
      <DeleteButton callFunction={deleteSwitchCases} comId={switchCase}/>
       </div>
         <div>
          <label className="nodePanel-labels mt-3">Value</label>
         <VariablePick input={switchCase.value} setInput={(value) => {
            setSwitchCases(
                switchCases.map((switchCa) => {
                if (switchCa.id === switchCase.id) {
                  return {
                    ...switchCa,
                    value: value,
                  };
                }
                return switchCa;
              })
            );
          }} vars={vars} />
         </div>
          

      </li>
    )
  });
  return (
    <div className="right_panel_workflow">
      <SidePanelClose nodeName={nodeName } />
<section className="sideInnerParent">

      <div >
        <label className="nodePanel-labels">{type + " Name"} </label>
<div className="labelInputFull">
<input
          type="text"
          className="nodePanel-inputs"
          value={data.name || ""}
          maxLength={15}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          placeholder={type + "Node" + " Name"}
        />
</div>
       
      </div>
      <div>
        <label className="nodePanel-labels" htmlFor="">Variable*</label>
        <div className="labelInputFull">
        <input value={data?.variable || ""} onChange={(e)=> setData({...data,variable:e.target.value})} type="text" />
        </div>

      </div>
      <div>
        <label htmlFor="">Operator</label>
        <div className="labelInputFull">
        <select

onChange={(e) =>
    setData({ ...data, operator: options[e.target.selectedIndex - 1] })
  }>

<option>Select Operator</option>
{options.map((option) =>{
 
return (
  <option selected={option === data.operator}>
    {SymbolMap[option]}
  </option>
)
} )}
</select>
        </div>
      </div>
      <div  >
            {
              switchCases && switchCases.length >=1 ?

              <ul className="commonBackGray">{SwitchCasesOptions}</ul>
        
              
                :<></>
            }
          
                <div className="addConditions">
              <AddButton
                callFunction={callAddSwitchCases}
                opName='Add Cases'
                />
            </div>
          </div>
      </section>
    </div>
  );
};

const SwitchNode = ({ id, data, selected }) => {
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected)
  }, [selected])
  return (
    <div
      className={
        " "
        // +
        // (data.isStartNode ? "start_node" : "") +
        // (!data.isStartNode && selected ? "selected_node" : "")
      }
    >
      <div style={{ backgroundColor: "#E1FFE6", border: selected ? `solid 2px${"#00B51F"}` : "" }} className={`${"set_msgcard"} ${"commonHeader"}`}>
        <NodeHeader
          name={nodeName}
          icon={Icons.updateNodeLogo}
          // onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        ></NodeHeader>
        <div className="updateNodeStyle">
          <div>
            <p>POST</p>
            <Handle
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchNode;
