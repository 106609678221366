import React from "react";
import { allValues } from "components";
import PageBuilderAssets from "../assets/pageBuilder/pageBuilderAssets";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';



let nodes = {};
allValues().forEach((comp) => {
    if (!nodes.hasOwnProperty(comp.category)) {
        nodes[comp.category] = [];
    }
    nodes[comp.category].push(comp.node);
});

const sections = Object.entries(nodes).map(([key, value]) => ({
    title: key,
    items: value.map((C) => <C />),
}));

const handleSearch = () => {
    alert(sections);
    console.log(sections?.title, 'sectionsData')
}
export const LeftPanel = () => {
    return (
        <>
            {/* Accordion Modal for Sidebar */}
            {/* <div className="col-lg-2 PBbg-color PBscrollalign">
                <h4 className="text-center color-white">Page Builder</h4>
                <section className="PBleftbar">
                    {sections.map((section, index) => (
                        <Accordion className="PBasideflow">
                            <Accordion.Item eventKey={`${index}`} className="PBbrdNone">
                                <Accordion.Header className="PBaccheader">
                                    {section.title}
                                </Accordion.Header>
                                <Accordion.Body className="PBaccbody">
                                    {section.items}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    ))}
                </section>
            </div> */}

            <div className="page-sidebar-main">
                <div className="group">
                    <svg className="icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
                    <input placeholder="Search" type="search" className="input" onChange={handleSearch} />
                </div>
                {/* Tabs for Template */}
                <Tabs
                    defaultActiveKey="custom"
                    id="justify-tab-example"
                    className="mb-3 mt-3 sidebar-main-tabs"
                    justify
                >
                    <Tab eventKey="templates" title="Templates" >
                        <div className="templateComingSoon">
                            <img src={PageBuilderAssets?.comingSoon} alt="Coming Soon" />
                            <h6 className="text-center">Coming Soon...</h6>
                        </div>
                    </Tab>

                    {/* Categories Tab */}

                    <Tab eventKey="custom" title="Custom">
                        <Tabs defaultActiveKey="0"
                            id="justify-tab-example"
                            className="mb-3 category-tabs flex-nowrap"
                            justify
                        >
                            {sections.map((section, index) => {
                                return <Tab eventKey={`${index}`} title={section.title}>
                                    <div className="container">
                                        <ul className="row p-0">
                                            {section.items}
                                        </ul>
                                    </div>
                                </Tab>
                            })}
                        </Tabs>
                    </Tab>
                </Tabs>
            </div>
        </>
    );
};
